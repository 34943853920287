.titleBar {
  padding: 24px 50px 23px !important;
  border-bottom: 1px solid #e6e6e6;
  position: fixed !important;
  z-index: 9;
  left: 350px;
  right: 0;
}
.controlBar {
  position: fixed;
  top: 88px;
  left: 400px;
  right: 50px;
  z-index: 99;
  padding: 45px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border-top: 1px solid #e6e6e6;
  line-height: 0px !important;
  background-color: #fff;
  // position: fixed;
  .ptCount {
    color: #9b9b9b;
  }
  :global .iconfont {
    font-size: 12px;
    transform: scale(0.8);
  }
  .toptexts{
    height: 38.8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.couponList {
  //border-top: 1px solid #e6e6e6;
  padding: 175px 50px 20px !important;
  :global .ant-card-body {
    padding: 0 !important;
  }
  :global .ant-table-thead > tr > th{
    line-height: 20px;
  }
  :global .ant-table-tbody > tr > td {
    font-size: 14px;
    padding: 15px 16px;
  }
  .nameCell {
    cursor: pointer;
    font-weight: bold;
  }
  .isDefault {
    margin-left: 8px;
    background-color: rgba(240, 168, 69, 0.9);
    border-radius: 9px;
    font-size: 11px;
    display: inline-block;
    line-height: 16px;
    -webkit-transform: scale(0.9);
    color: #fff;
    padding: 3px 6px 1px;
  }
}
.settingsMenu{
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}
:global .ant-table-pagination.ant-pagination {
  margin: 30px 0 !important;
}
.remarkTip {
  justify-content: center;
  display: flex;
  text-align: center;
  line-height: 18px;
  padding-top: 10px;
  color: #757575;
  font-size: 13px;
}
.menuBtn {
  text-align: right;
}
.ant-layout-content{
  position: absolute;
  padding: 0 50px;
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;