.body {
  padding-bottom: 20px;
  .listItem {
    .label {
      font-size: 14px;
      color: #4a4a4a;
      margin-right: 5px;
    }
  }
  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      color: #9B9B9B;
      margin: 0 15px;
      padding: 0;
    }
  }
  .weixin{
    margin: 36px 0 10px;
    .btn {
      font-size: 13px;
      color: #1AA77D;
      margin: 0 7px;
    }
    .code{
      margin: 0 auto;
      text-align: center;
    }
    .steps{
      display: flex;
      justify-content: center;
      align-items: center;
      color: #757575;
      font-size: 12px;
      margin-top: 10px;
      div{
        display: flex;
        align-items: center;
      }
      .line{
        display: inline-block;
        height: 1px;
        width: 11px;
        background:  #CFD1D7;
        margin-left: 5px;
      }
      .round{
        display: inline-block;
        height: 11px;
        width: 11px;
        border: 1px solid #CFD1D7;
        border-radius: 50%;
        margin: 0 5px;
      }
    }
  }
  .email{
    .sendRecord{
      p{
        color:#757575;
        font-size:13px;
        cursor: pointer;
        margin-top: 20px;
      }
      span{
        font-size: 13px;
        line-height: 20px;
      }
      div{
        width: 100%;
        padding: 8px 0 0;
        max-height: 100px;
        overflow: auto;
        color: #9B9B9B;
        line-height: 18px;
      }
      :global .iconfont {
        font-size: 6px;
      }
    }
    .clearButton {
      display: flex;
      margin-top: 3px;
      font-weight: 300;
    }
    :global .ant-select-multiple .ant-select-selection-item {
      margin-bottom: 0;
    }
  }
  .link{
    .linkcontent{
      text-align: center;
      height: 80px;
      background: #EFEFEF;
      border: 1px solid #E1E6E4;
      padding-top: 20px;
      margin: 20px 0 30px;
      color: #9B9B9B;
      font-size: 12px;
      font-weight: bold;
    }
    div{
      text-align: center;
    }
  }
}

.popTip{
  width: 360px;
  :global .ant-popover-inner-content {
    background: #4a4a4a;
    color: #fff;
    font-size: 12px;
  }
}
.select{
  :global .ant-select-selector {
    max-height: 100px;
    overflow: auto;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;