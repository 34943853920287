.formContrl {
  max-width: 1000px;
  min-width: 600px;
  :global .ant-form-item-label {
    font-weight: bold;
  }
  :global .ant-divider-horizontal {
    margin: 30px 0 25px 0;
  }
  :global .ant-col-16 {
    max-width: 80%;
  }
}
.description {
  color: #9b9b9b;
  margin-top: 1rem;
}
.markstaUpload {
  :global .logoUpload {
    button {
      top: 45%;
      margin-top: -16px;
    }
    :global .ant-upload.ant-upload-select-picture-card > .ant-upload {
      display: inline-block;
      padding: 0;
      width: 250px;
      height: 150px;
    }
    :global .ant-upload-select-picture-card {
      border: none;
    }
    :global .ant-upload-list-picture-card-container {
      width: 250px;
      height: 150px;
      padding: 0;
    }
    :global .ant-upload-list-picture-card .ant-upload-list-item {
      width: 250px;
      height: 150px;
      padding: 0;
    }
    .img {
      width: 250px;
      height: 150px;
      object-fit: cover;
    }
    .uploadCover {
      width: 250px;
      height: 150px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}
.switch {
  margin-bottom: 10px;
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;