/*
 * @Author: melon
 * @Date: 2020-06-28 21:11:55
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-17 19:40:12
 */
.subscribe_form {
  max-width: 1000px;
  min-width: 600px;
  .subscribe_title,
  .subscribe_use_progress,
  .subscribe_description {
    width: 100%;
  }
  .subscribe_version {
    margin-top: 50px;
    width: 100%;
    .subscribe_child{
      display: flex;
      justify-content: flex-start;
      >div{
        flex: 1;
      }
    }
  }
  :global .iconfont {
    font-size: 12px;
  }

  // 订阅版本
  .subscribe_title {
    margin-bottom: 20px;

    span.version,
    span.due_date {
      display: inline-block;
      font-size: 14px;
      color: #4A4A4A;
      font-weight: bold;
      margin-left: 0px;
    }
  }

  // 进度条
  .subscribe_use_progress {
    margin-bottom: 30px;

    .subscribe_use_text {
      font-size: 12px;
      color: #757575;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }


  }

  // 升级
  .subscribe_description {
    background: #F7F7F7;
    // min-height: 114px;
    padding: 23px 29px;

    .subscribe_description_text {
      font-size: 12px;
      color: #4A4A4A;
      // margin-bottom: 14px;
    }

    .subscribe_price {
      font-size: 12px;
      color: #000000;
      line-height: 17px;
    }
  }
  .goBackbtn {
    width: 28px;
    height: 22px;
    line-height: 20px;
    padding: 0;
    border-radius: 2px;
  }

}

.subscribe_form_modal {
  :global .ant-modal-content .ant-modal-close .ant-modal-close-x:before {

    content: none;
  }

  .back {
    font-size: 12px;
    display: inline-block;
    font-weight: initial;
  }

  .subscribe_form_modal_close:before {
    font-family: 'iconfont' !important;
    font-size: 30px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\e62c';
    font-weight: bold;
  }

}

// 升级弹窗
.upgrade_modal_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  :global .Inf_page_title {
    font-size: 16px;
  }

  p {
    color: #9b9b9b;
  }

  .upgrade_modal_title {
    width: 100%;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;

    p.text_item {
      font-size: 12px;
    }

    .upgrade_price {
      text-align: right;

      p {
        font-size: 12px;
        color: #9b9b9b;
      }

      .month_price {
        color: @primary-color;
        font-weight: bold;
        font-size: 16px;
      }
    }


  }

  .upgrade_modal_content {
    width: 100%;
    text-align: center;
    padding: 24px 0;
    min-height: 300px;

    div.text_item {
      margin: 20px 0;

      // p {
      //   font-weight: bold;
      // }

      p.text_important {
        font-weight: bold;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  .total_wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .pay_total {
      margin-right: 24px;
    }
  }


}

.upgrade_modal_footer {
  text-align: center;

  .price_bold {
    font-weight: 600;
    font-size: 16px;
    display: inline-block;
    margin-right: 5px;
  }

  .price_btn {
    height: 50px;
  }

  .upgrade_modal_footer_btn_wrapper {
    margin: 20px 0;

    .upgrade_modal_footer_span {
      display: inline-block;
      vertical-align: middle;
      height: 70px;
    }
  }

  .primary_btn {
    border-color: @primary-color;
    color: @primary-color;
  }

  .create_btn {
    width: 100px;
    margin-right: 30px;
  }
}

.pay_way_wrapper {
  text-align: left;

  :global .Inf_page_text {
    margin: 10px 0;
  }

  .pay_btn_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      min-width: 130px;
      height: 50px;
      line-height: 30px;
    }

    .pay_way_active {
      border: 1px solid #1AA77D;
      // background-color: #C4F1E4;
      color: #1AA77D;

      :global .iconico_wepay {
        color: #0F9D58;
      }

      :global .iconico_alipay {
        color: #0A86FF;
      }
    }

    :global .iconfont {
      margin-right: 5px;
      font-size: 20px;
      vertical-align: middle;

    }

    :global .iconico_wepay {
      color: #00c250;
    }

    :global .iconico_alipay {
      color: #027aff;
    }
  }
}

.month_input {
  :global .ant-input-number-input {
    height: 30px;
  }
}
.subscribe_ul {
  padding: 0;
  .subscribe_li {
    font-size: 12px;
    color: #4A4A4A;
    list-style: none;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-top-color: transparent;
    border-left-color: transparent;
    height: 40px;
    line-height: 40px;
    &:first-child{
      height: 130px;
    }
    &:nth-child(3){
      display: flex;
      align-items: center;
      line-height: 18px;
      height: 50px;
      div {
        width: 100%;
        span {
          display: block;
          width: 100%;
        }
      }
    }
    &:last-child{
      border-bottom-color: transparent;
      height: 50px;
      line-height: 50px;
    }
  }
  .subscribe_center{
    text-align: center;
  }
  .subscribe_btn{
    background: #1AA77D;
    border-radius: 2px;
    color:#fff;
    display:inline-block;
    height: 28px;
    line-height: 28px;
    padding:0 10px;
    cursor:pointer;
  }
}
.detailsLi{
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 10px 0;
  .photo {
    width: 75px;
    height: 50px;
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.projectMenu {
  // position: relative;
  // margin: 0px;
  padding: 10px 20px;
  // text-align: left;
  // list-style-type: none;
  background-color: #fff;
  // background-clip: padding-box;
  // outline: none;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 36px !important;
    height: 36px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}

// 列表中无内容时的提示样式
.nullTips {
  font-size: 13px;
  color: #757575;
  margin-top: 20px;
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;