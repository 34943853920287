.titleBar {
  padding: 24px 50px 23px !important;
  border-bottom: 1px solid #e6e6e6;
  .backBtn {
    width: 28px;
    height: 22px;
    line-height: 20px;
    padding: 0;
    margin-right: 10px;
    :global .iconfont {
      font-size: 12px;
    }
  }
  .cancelBtn {
    color: #9b9b9b;
    border: none;
    box-shadow: none;
  }
}
.newProductsPage {
  overflow-x: hidden;
  height: calc(100vh - 88px);
  padding: 30px 50px;
  background: #fff;
}

.productForm {
  width: 80%;
  .desc {
    font-size: 12px;
    color: #9b9b9b;
    padding-top: 6px;
    font-weight: normal;
  }
  .switchLabel {
    margin-left: 12px;
  }
  :global .ant-form-item-control-input {
    min-height: 20px;
  }
  :global .ant-form-item-label {
    font-weight: bold;
  }
  :global .ant-input-number{
    width: 100%;
  }
  .productContent {
    max-width: 800px;
    min-width: 600px;
    :global .ant-input-number-handler-wrap {
      display: none
    }
    .miniMaxInput {
      :global .ant-input-number {
        width: 100%;
      }
    }
  }
  :global .iconfont{
    font-size: 12px;
    cursor: pointer;
  }
  .hint {
    font-size: 12px;
    color: #9B9B9B;
    span{
      cursor: pointer;
      color: #1AA77D;
    }
    .addButton {
      padding-left: 10px;
    }
  }
  .wrap {
    height: 100%;
    width: 100%;
    .list {
      padding: 0;
      margin-bottom: 3px;
      display: flex;
      justify-content: flex-start;
    }
    .item {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      width: 100px;
      margin-right: 10px;
      cursor: pointer;
      background: #EFEFEF;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      :global .iconfont{
        position: absolute;
        top: 5px;
        right: 5px;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        color: #E02020;
        background: #FFFFFF;
        border-radius: 2px;
        transform: scale(0.88);
      }
    }
    .additem{
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      width: 100px;
      margin-right: 20px;
      // margin-bottom: 10px;
      cursor: pointer;
      background: #EFEFEF;
      :global .iconfont {
        color: #9B9B9B;
      }
      input{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0;
        cursor: pointer;
        z-index: 999;
      }
    }
  }
  .productOpts{
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    margin-top: 6px;
    >div{
      color: rgba(10,134,255,1);
      background: rgba(229,243,255,1);
      padding: 0 10px;
      margin: 5px 10px 5px 0;
      line-height: 30px;
      >span {
        padding-right: 10px;
      }
      :global .iconfont{
        margin-left: 10px;
        padding-right: 0;
      }
    }
  }
    .Ptitle,.content{
      display: flex;
      justify-content: left;
      border-bottom: 0.5px solid #E1E1E1;
      font-size: 12px;
      &.Ptitle{
        color: #9B9B9B;
        line-height: 30px;
        >div{
          text-align: center;
        }
      }
      &.content{
        font-size: 13px;
        :global .iconfont{
          font-size: 14px;
        }
        div{
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .opt{
        flex: 1;
        justify-content: flex-start!important;
        text-align: left!important;
        .img{
          position: relative;
          width: 36px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          background: #EFEFEF;
          margin-right: 10px;
          cursor: pointer;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
          }
          input{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 0;
            cursor: pointer;
            z-index: 999;
          }
          :global .iconfont{
            font-size: 12px;
            color: #9b9b9b;
          }
        }
      }
      .price{
        width: 120px;
      }
      .addPagePrice{
        width: 120px;
      }
      .inputred{
        border-color: #CD292A;
      }
      .shipping{
        width: 120px;
      }
      .isShow{
        width: 30px;
        margin-left: 20px;
        .iconfont{
          cursor: pointer;
          font-size: 16px;
        }
      }
      :global .ant-input{
        height: 28px;
        width: 80px;
        text-align: right;
      }
  }
}


@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;