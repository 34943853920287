.createDialog {
  position:relative;
  :global .ant-select-arrow {
    right: 8px;
    :global .iconfont {
      font-size: 12px;
    }
  }
  .more{
    position: absolute;
    bottom: -80px;
    color: #066DD5;
    font-size: 13px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
  }
  .switchLabel {
    margin-left: 12px;
  }
  .check{
    position: relative;
    :global .ant-checkbox-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      .ant-checkbox-inner {
        border-radius: 50%;
      }
    }
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;