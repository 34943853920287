.dashboardPage {
  display: flex;
  justify-content: center;

  :global .ant-select-arrow {
    right: 8px;
    :global .iconfont {
      font-size: 12px;
    }
  }
  .body {
    padding: 35px 20px;
    max-width: 985px;
    .welcomeTitle {
      font-size: 16px;
      font-weight: bolder;
      color: #000000;
      margin-bottom: 50px;
    }
    .banner {
      margin-top: 80px;
      .welcomeImg {
        width: 100%;
        //height: 300px;
        object-fit: fill;
      }
    }
  }

  .dashboardPorject{
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom:40px;
    align-items: flex-start;
    .projectLeft{
      flex-grow: 0;
      width:50%;
      margin-right:20px;
      .projectItem{
        display: flex;
        align-items: center;
        border:1px solid #EFEFEF;
        border-radius: 2px;
        padding:30px 25px 25px 25px;
        margin-bottom:30px;
        &:last-child{
          margin:0;
        }
        .itemTit{
          flex-grow: 1;
          font-size: 18px;
          :global .iconfont{
            color:#757575;
            font-size: 30px !important;
            padding-right: 10px;
          }
        }
        .itemNum{
          flex-grow: 0;
          width:100px;
          text-align: right;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    .projectRight{
      border:1px solid #EFEFEF;
      border-radius: 2px;
      padding:20px;
      width:50%;
      margin-left:20px;
      h4{
        font-size: 16px;
      }
      .projectMsg{
        .projectMsgItem{
          padding:15px 0;
          border-bottom: 1px solid #EFEFEF;
          display: flex;
          align-items: flex-start;
          .projectMsgItemIcon{
            margin:0;
            padding:0 10px 0 0;
            flex-grow: 0;
            :global .iconfont{
              font-size: 30px;
            }
            :global .iconicon_msg_normal{
              color:#F7B500;
            }
            :global .iconicon_msg_remind{
              color:#1AA77D;
            }
          }
          .projectMsgItemInfo{
            flex-grow: 1;
            p{
              margin-bottom:0;
              font-size: 14px;
            }
            .projectMsgItemInfoDate{
              font-size: 12px;
              color:#9B9B9B;
            }
          }
          .projectMsgItemDel{
            color:#ddd;
            flex-grow: 0;
            padding-left:10px;
            :global .iconfont{
              font-size: 20px;
            }
          }
        }
      }
      .showAll{
        text-align: center;
        font-size: 12px;
        margin:20px 0 0 0;
        a{
          color:#9B9B9B;
        }
      }
    }
  }
}


@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;