.createProjectDialog {
  .Inf_ant_form_item {
    display: inline-block;
    margin-right: 20px!important;
    .Inf_Province_Select_Cascader--bqWNm {
      .ant-select {
        margin-right: 10px !important;
      }
    }
  }
    :global .ant-select-arrow {
      right: 8px;
      :global .iconfont {
        font-size: 12px;
      }
    }
  }

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;