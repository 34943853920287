.titleBar {
  padding: 24px 50px 23px !important;
  border-bottom: 1px solid #e6e6e6;
  position: fixed !important;
  z-index: 9;
  left: 350px;
  right: 0;
  .backBtn {
    width: 28px;
    height: 22px;
    line-height: 20px;
    padding: 0;
    margin-right: 10px;
    :global .iconfont {
      font-size: 12px;
    }
  }
}
.earnings {
  padding: 130px 50px 20px !important;
  :global .ant-card-body {
    padding: 0 !important;
  }
  :global .ant-table-thead > tr > th{
    line-height: 20px;
  }
  :global .ant-table-tbody > tr > td {
    font-size: 14px;
    padding: 15px 16px;
    padding-right: 0;
  }
  :global .ant-table-container table > thead > tr:first-child th:last-child {
    padding-right: 0;
  }
  .nameCell {
    cursor: pointer;
    font-weight: bold;
  }
  .saleProceeds{
    display: flex;
    justify-content: space-between;
    align-items: center;
    >div{
      border: 0.5px solid #E1E1E1;
      box-shadow: 0 0 2px 0 rgba(0,0,0,0.10);
      border-radius: 2px;
      height: 200px;
      text-align: center;
      padding-top: 30px;
      .title{
        font-size: 15px;
        font-weight: bold;
        span {
          padding-right: 8px;
        }
      }
      .earnTotal{
        span{
          font-size: 36px;
          font-weight: 500;
        }
      }
      .desc{
        font-size: 12px;
        color: #9B9B9B;
        margin-bottom: 10px;
        span{
          cursor: pointer;
          color: #066DD5;
        }
      }
      .forOrder{
        font-size: 14px;
        color: #4A4A4A;
        // span{
        //   margin: 0 5px;
        // }
      }
      .withdraw{
        width: 90px;
        height: 32px;
        line-height: 32px;
        background: #1AA77D;
        border-radius: 2px;
        font-size: 14px;
        color: #FFFFFF;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .flexs{
      width: 30px;
      border: none;
      box-shadow: none;
    }
    .sale{
      flex: 1;
    }
    .proceeds{
      flex: 1;
    }
  }
  .hearders{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 30px 0 15px;
    .title{
      font-weight: bold;
    }
    .more{
      color: #066DD5;
      cursor: pointer;
    }
  }
}
.earningsRecord{
  padding: 110px 50px 20px !important;
  .controlBar{
    margin-bottom: 20px;
    .ptCount {
      color: #9b9b9b;
    }
    :global .iconfont {
      font-size: 12px;
      transform: scale(0.8);
    }
  }
  .saleProceeds{
    >div{
      height: 160px;
    }
  }
}
.withdrawRecord{
  padding: 115px 50px 20px !important;
  .controlBar{
    margin-bottom: 20px;
    .ptCount {
      color: #9b9b9b;
    }
  }
}
.sortMenu {
  position: relative;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-divider {
    height: 1px !important;
    margin: 8px 0 !important;
    // background-color: #f0f0f0 !important;
  }
  .titleName {
    color: #9b9b9b !important;
    font-size: 11px;
  }
  :global .ant-picker{
    position: absolute;
    bottom: 0;
    cursor: pointer;
    opacity: 0;
  }
}
.settingsMenu{
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}
.checkOrders{
  font-size: 14px;
  color: #066DD5;
  cursor: pointer;
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;