.Inf_msg_list {
  :global .ant-card-body {
    padding: 0;
  }

  :global .ant-card-meta-title {
    font-size: 14px;
    color: #151515;

    .customer_name {
      font-weight: bold;
    }
  }

  :global .ant-card-meta-detail>div:not(:last-child) {
    margin: 0;
  }
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;