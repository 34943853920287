.logo {
  line-height: 1;
  padding: 36px 30px;

  .logoImg {
    width: 82.2px;
    height: 14px;
  }
}

.bottomMenus {
  bottom: 22px;
  position: absolute;
  width: 100%;
  :global .ant-menu-item{
    overflow: inherit!important;
  }
}

.user {
  padding-left: 30px;
  margin-bottom: 15px;

  :global .ant-dropdown-trigger > .anticon.anticon-down,
  :global .ant-dropdown-link > .anticon.anticon-down,
  :global .ant-dropdown-button > .anticon.anticon-down {
    vertical-align: text-top;
  }

  .userName {
    font-size: 12px;
    color: #757575;
    // padding-right: 32px;
    font-weight: bold;
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }

  .messages {
    color: #aaacb1;
    // margin-left: 14px;
    font-size: 20px;
  }
}

.userMenu {
  left: 140px !important;
  :global .ant-popover-arrow {
    display: none;
  }
  :global .ant-popover-inner-content {
    padding: 0;
  }
}
.Inf_popover {
  top: 90px !important; //
  left: 140px !important;

  :global .ant-popover-title {
    border-bottom: 0;
  }

  :global .ant-popover-arrow {
    border-style: none;
  }

  .Inf_popover_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #000000;
    width: 320px;
    margin-bottom: 20px;

    .Inf_popover_close {
      cursor: pointer;
    }
  }

  .Inf_msg_list {
    :global .ant-card-body {
      padding: 0;
    }

    :global .ant-card-meta-title {
      font-size: 14px;
      color: #151515;

      .customer_name {
        font-weight: bold;
      }
    }

    :global .ant-card-meta-detail > div:not(:last-child) {
      margin: 0;
    }
  }

  .more_btn {
    width: 100%;
    text-align: center;
  }

  .msg_time {
    font-size: 12px;
    color: #9b9b9b;
  }
}
.accMenu {
  padding: 10px 20px;
  background-color: #fff;
  // box-shadow: 0 0 3px 0 #aaacb1;
  button {
    padding: 0px;
  }
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 36px !important;
    height: 36px !important;
    padding: 0px !important;
    background-color: #fff !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}
.fbDialog {
  :global .ant-select-arrow {
    right: 8px;
    :global .iconfont {
      font-size: 12px;
    }
  }
}
.helpDialog {
  text-align: center;
  padding-bottom: 20px;
  img {
    width: 360px;
  }
}
.cart {
  line-height: 20px;
  span {
    font-size: 21px;
    padding-right: 5px;
  }
}
.adDialog{
  width: 800px;
  position: relative;
  :global .ant-modal-body {
    padding: 0 !important;
  }
  :global .ant-modal-content > .ant-modal-close {
    top: -50px;
    right: -15px;
    color: #f0f0f0;
  }
  img{
    width: 100%;
  }
  .copy{
    position: absolute;
    width: 100%;
    bottom: 30px;
    text-align: center;
    :global .ant-btn-primary {
      border: none;
      // background: #000000;
    }
    :global .ant-btn {
      padding: 5px 20px;
      line-height: 30px;
      height: 40px;
    }
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;