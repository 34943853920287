.checkArr{
  font-size: 14px;
  color: #4A4A4A;
  line-height: 20px;
  font-weight: 400;
  &:hover{
    color: #4A4A4A;
  }
}
:global .ant-page-header .anticon svg{
  visibility: hidden!important;
}
.account_cell {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 8px;
    width: 36px;
    height: 36px;
    border-radius: 18px;
  }

  .nickname {
    font-size: 14px;
    color: #4A4A4A;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 400;
  }
  .mobile {
    font-size: 12px;
    color: #9B9B9B;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 400;
  }
}
.controlBar {
  padding: 30px 50px 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e6e6e6;
  background-color: #fff;
  // position: fixed;
  .ptCount {
    color: #9b9b9b;
  }
  .info{
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    font-weight: bold;
    >div{margin-bottom: 30px}
    .names{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img{
        margin-right: 8px;
        width: 36px;
        height: 36px;
        border-radius: 18px;
      }
    }
    label{
      display: inline-block;
      color: rgba(74,74,74,.8);
      font-size: 12px;
      line-height: 20px;
    }
  }
  //.iconfont {
  //  font-size: 12px;
  //  transform: scale(0.8);
  //}
  h3 {
    color: rgba(74,74,74,1);
    font-size: 14px;
    font-weight: bold;
  }
  .list{
    border-top: 1px solid rgba(225,225,225,.5);
    // border-bottom: 1px solid rgba(225,225,225,.5);
    margin-bottom: 40px;
    // max-height: 200px;
    overflow: auto;
    .custProject{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(225, 225, 225, .5);
      .custProjects {
        display: flex;
        justify-content: flex-start;
        padding: 15px 0;
        img {
          width: 40px;
          height: 40px;
          //width: 75px;
          //height: 50px;
          object-fit: cover;
          margin-right: 8px;
        }
        div {
          line-height: 20px;
          p {
            //color: rgba(6,109,213,1);
            color: #4a4a4a;
            font-weight: bold;
            font-size: 14px;
          }
          span {
            color: rgba(155, 155, 155, 1);
            font-size: 12px;
          }
        }
      }
    }
    .nocontent {
      padding: 15px 0;
      color: #9B9B9B;
    }
    .orders{
      font-size: 14px;
      color: rgba(74,74,74,1);
      padding: 15px 0;
      border-bottom: 1px solid rgba(225,225,225,.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .times{
        color: rgba(155,155,155,1);
        font-size: 11px;
      }
      .iconbtn_list_set{
        font-size: 16px;
      }
    }
  }
}

.search{
  float: right;
  width:240px!important;
  border: 1px solid #EFEFEF !important;
  padding: 6px 8px !important;
  background: #EFEFEF!important;
  input{
    background: transparent;
  }
  :global .iconfont {
    font-size: 16px;
    color: #9b9b9b;
  }
}
.searchs{
  float: right;
  width:240px!important;
  border: 1px solid #E1E1E1 !important;
  padding: 6px 8px !important;
  background: transparent!important;
  input{
    background: transparent;
  }
  :global .iconfont {
    font-size: 16px;
    color: #9b9b9b;
  }
  .clearInput {
    font-size: 12px;
    color: #4a4a4a;
    margin-right: 0px;
    cursor: pointer;
  }
}
.sortMenu {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
  .titleName {
    color: #9b9b9b !important;
    font-size: 11px;
  }
}
.tables {
  padding: 10px 50px!important;
  :global .ant-card-body {
    padding: 0 !important;
  }
  :global .ant-table-thead > tr > th{
    line-height: 40px;
  }
}
.settingsMenu{
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
}
.btns {
  padding: 0;
  .btn {
    margin: 20px 10px 0 0;
    padding: 0;
    background: rgba(10, 134, 255, .1);
    span {
      display: inline-block;
      padding: 5px 7px;
      color: #0A86FF;
    }
  }
}
.tabs{
  position: relative;
  margin:0 10px 10px 0;
  background:#F2F2F2!important;
  font-size: 13px!important;
  border: #F2F2F2!important;
  color: #4A4A4A!important;
  i {
    display: none;
  }
  &:hover {
    //padding-right: 20px;
    i {
      position: absolute;
      top: 0;
      right: 0;
      height: 15px;
      width: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #b0341e;
      color: #fff;
    }
  }
  // :global .ant-btn {
  //   border-color: #000000;
  // }
}
.btnBack {
  :global .iconfont {
    font-size: 12px;
  }
}
.clearButton {
  display: flex;
  margin-top: 3px;
  font-weight: 300;
}
.activity{
  overflow: auto;
  div{
    color:#4A4A4A;
    font-size:12px;
    line-height:27px
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;