.formContrl {
  max-width: 1000px;
  min-width: 600px;
  :global .ant-form-item-label {
    font-weight: bold;
  }
  :global .ant-divider-horizontal {
    margin: 30px 0 25px 0;
  }
}
.description {
  color: #9b9b9b;
  margin-top: 6px;
  font-size: 12px;
}
.markstaUpload {
  :global .logoUpload {
    :global .uploadArea {
      margin-top: 60px;
      .upload-hint {
        font-size: 12px;
        color: #9b9b9b;
        margin-top: 11px;
      }
    }
    button {
      top: 45%;
      margin-top: -16px;
    }
    :global .ant-upload.ant-upload-select-picture-card > .ant-upload {
      display: inline-block;
      padding: 0;
      width: 250px;
      height: 150px;
    }
    :global .ant-upload-select-picture-card {
      border: none;
      width: 250px;
      height: 150px;
    }
    :global .ant-upload-list-picture-card-container {
      width: 250px;
      height: 150px;
      padding: 0;
    }
    :global .ant-upload-list-picture-card .ant-upload-list-item {
      width: 250px;
      height: 150px;
      padding: 0;
    }
    .img {
      width: 250px;
      height: 150px;
      object-fit: contain;
    }
    .uploadCover {
      width: 250px;
      height: 150px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  :global .ant-form-item-control-input-content{
    position: relative;
  }
  .watermark{
    position: absolute;
    left: 0;
    top: 0;
    width: 250px;
    height: 150px;
    cursor: pointer;
  }
}
.position {
  :global .ant-radio-button-wrapper {
    height: 50px;
    padding: 0 25px;
  }
  :global .ant-radio-button-wrapper:first-child {
    border-radius: 2px 0 0 0;
  }
  :global .ant-radio-button-wrapper:nth-child(3) {
    border-radius: 0 2px 0 0;
  }
  :global .ant-radio-button-wrapper:nth-child(5),
  :global .ant-radio-button-wrapper:nth-child(7) {
    border: none;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
  }
  :global .ant-radio-button-wrapper:nth-child(6) {
    border: none;
  }
  :global .ant-radio-button-wrapper:nth-child(9) {
    border-left: 1px solid #d9d9d9;
    border-radius: 0 0 0 2px;
  }
  :global .ant-radio-button-wrapper:last-child {
    border-radius: 0 0 2px 0;
  }
  .example1 {
    margin-left: 50px;
    width: 100px;
    height: 150px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
    .positonArea {
      height: 50px;
    }
  }
  .example2 {
    margin-left: 50px;
    width: 225px;
    height: 150px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;

    // display: grid;
    // grid-template-columns: 90px 90px 90px;
    // grid-template-rows: 50px 50px 50px;
    // justify-items: center;
    // align-items: center; // 交叉轴居中

    // display: table;
    // :global li {
    //   display: table-row;
    //   background: #beffee;
    //   :global div {
    //     width: 90px;
    //     line-height: 50px;
    //     display: table-cell;
    //     text-align: center;
    //   }
    // }
    .positonArea {
      height: 50px;
    }
  }
  .markstaImg {
    width: 100%;
    height: 100%;
    // width: 70px;
    // height: 68px;
    object-fit: contain;
    // object-position: left;
    opacity: 0.5;
    // position: absolute;
    // top: 50%;
    // left: 50%;
  }
}
.switch {
  margin-bottom: 10px;
  span {
    margin-left: 14.8px;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;