.recordListForm {
  :global .ant-form-item-label {
    font-weight: bold;
    color: #4a4a4a;
    font-size: 14px;
  }

  :global .ant-select-arrow {
    right: 8px;
    :global .iconfont {
      font-size: 12px;
    }
  }

  .baseForm {
    // max-width: 800px;
    min-width: 600px;
    :global .ant-card-body {
      padding: 0;
    }
    :global .ant-table-tbody > tr > td {
      font-size: 14px;
    }
    :global .ant-divider-horizontal {
      margin: 0 0 25px 0;
    }
    .ptCount {
      color: #9b9b9b;
    }
    .spinTip {
      margin-top: 50px;
    }
    :global .ant-pagination-options{
      display: none;
    }
  }
}

.settingsMenu {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}

.ant-card-body{
  padding: 0;
}
  .names {
    height: 30px;
    line-height: 30px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 50%;
    }
    .client{
      margin-left: 8px;
      background-color: rgba(6, 109, 213, 0.5);
      border-radius: 9px;
      font-size:11px;
      display: inline-block;
      line-height: 16px;
      -webkit-transform: scale(0.9);
      color: #fff;
      padding: 3px 6px 1px;
    }
  }
.hisRecords {
  display: flex;
  align-items: center;
  .photoBlock {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid #e1e6e4;
  }
  .photo {
    max-width: 60px;
    max-height: 60px;
    object-fit: cover;
  }
  .photoName {
    font-size: 13px;
    padding-left: 10px;
    width: 200px;
    display: inline-block;
    line-height: 16px;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;