.myProject {
  .controlBar {
    color: #9b9b9b;
    margin: -10px 0 20px;
    // span {
    //   font-size: 13px;
    // }
  }
  .record {
    // margin: 10px 0;
    padding: 15px 0;
    border-bottom: 1px solid #EFEFEF;

    .recordAcc {
      font-size: 13px;
      color: #9b9b9b;
      font-weight: 500;
    }
    .recordIp {
      font-weight: 500;
      line-height: 32px;
      padding-left: 10px;
    }
    .recordEnv {
      font-size: 13px;
      color: #757575;
    }
    :global .iconfont {
      font-size: 14px;
    }
  }
  .pagerPositon{
    text-align: center;
    margin: 20px 0 30px !important;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;