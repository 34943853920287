.projectInfo{
  margin: 0 auto;
  .project{
    display: flex;
    justify-content: space-between;
    max-width: 1600px;
    .imgs{
      width: 54%;
      .bigImg{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &:before {
          content: '';
          display: block;
          padding-bottom: 100%;
        }
        img{
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      ul{
        padding: 0;
        margin: 15px 0 15px 0;
        display: flex;
        justify-content: flex-start;
        li{
          width: 100px;
          height: 100px;
          list-style-type: none;
          margin-right: 15px;
          cursor: pointer;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .info{
      width: 50%;
      margin-left: 30px;
      //width: calc(100% - 750px);
      .name{
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        letter-spacing: 0;
        font-weight: bold;
      }
      .price{
        font-size: 14px;
        color: #4A4A4A;
        letter-spacing: 0;
        margin: 10px 0;
      }
      .information {
        display: inline-block;
        background: #f6e18d;
        color: rgba(0,0,0,.6);
        font-size: 12px;
        padding: 6px 10px 4px;
        margin: 10px auto;
      }
      .productOverview{
        margin: 20px 0;
        font-size: 14px;
        color: #757575;
        letter-spacing: 0;
      }
      .options{
        margin: 30px 0 0 0;
        .opt{
          font-size: 12px;
          color: #4A4A4A;
          font-weight: bold;
          margin-bottom: 5px;
        }
        ul{
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          padding: 0;
          li{
            list-style-type: none;
            margin: 0 20px 10px 0;
            cursor: pointer;
            height: 36px;
            line-height: 36px;
            padding: 0 20px;
            background: #FFFFFF;
            color: #131415;
            border: 1px solid #DCDCDC;
            &.active{
              color: #200E32;
              border: 1px solid #200E32;
              font-weight: bold;
            }
          }
        }
      }
      .customization{
        width: 150px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background: #000;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        margin: 40px 0 50px;
        cursor: pointer;
      }
      .textTitle{
        margin: 25px 0 0;
        font-size: 14px;
        font-weight: bold;
        color: #4A4A4A;
      }
      .description {
        margin: 5px 0;
        font-size: 14px;
        color: #4A4A4A;
        line-height: 22px;
        white-space: pre-wrap;
      }
    }
  }
}

.qr {
  text-align: center;
  margin: -20px 0;
  img {
    width: 360px;
  }
  .qrtext {
    text-align: center;
    margin: 0 0 30px;
  }
  .qrok {
    display: inline-block;
    background: #000;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
  }
}


@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;