.goBackBtn {
  width: 28px;
  margin-right: 5px;

  .goBackIcon {
    //color: #9b9b9b;
    font-size: 12px;
  }
}

.feedback {
  width: 100%;
  height: 100%;
  line-height: 18px;
  margin: 20px 0;

  .feedbackTitle {
    font-size: 11px;
    color: #9b9b9b;
    margin-bottom: 8px;
  }

  // :global .ant-btn:hover {
  //   border-color: #9b9b9b;
  // }
  .feedbackBtn {
    width: 136px;
    height: 56px;
    padding: 4px 12px 0px 15px;
    //border: 1px solid #9b9b9b;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .serviceInfo {
      display: inline-block;
      width: 90px;
      :global div {
        line-height: 16px;
        display: list-item;
        text-align: start;
      }

      .name {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }

      .phone {
        font-size: 11px;
        color: #9b9b9b;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      :global div.ant-typography {
        margin-bottom: 0;
      }
    }

    .rightIcon {
      display: block;
      float: right;
    }
  }
}

.customization {
  width: 80%;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}
.shopMenu {
  :global .ant-popover-arrow {
    display: none;
  }
  :global .ant-popover-inner-content {
    padding: 0;
  }
}
.shopList{
  padding: 10px 20px !important;
  .typeName{
    cursor: pointer;
    line-height: 36px;
    a {
      color: #4a4a4a;
    }
    :hover {
      color: #1AA77D;
    }
  }
}

:global .copyPopover {
  // padding: 0;
  .copyBtn {
    text-align: end;
  }

  // :global .ant-popover-arrow {
  //   display: none;
  // }
}
.title {
  font-size: 11px;
  color: #9b9b9b;
}
.browse{
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  .col{
    width: 70px;
    height: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px 0;
    &:not(:last-child) {
      border-right: 1px solid #EFEFEF;
    }
    .font{
      font-size: 13px;
      width: 100%;
      font-weight: bold;
    }
    .text,.save{
      display: inline-block;
      width: 100%;
    }
    .font,.text{
      margin-top: -5px;
    }
    .save{
      color: #1AA77D;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
.Trailtext {
  :global .ant-card-body {
    padding: 30px 50px;
  }
  .dialogTitle {
    font-weight: bold;
  }
  .dialogDes {
    margin: 10px 0 30px;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;