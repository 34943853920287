@import '~antd/dist/antd.less';
// 引入iconfont
@import '../src/Assets/Iconfont/iconfont.css';
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
/*-------公共样式 --------*/
.them-sider.ant-layout-sider.ant-layout-sider-light {
  background: #f9f9f9;

  .ant-menu-light {
    background: #f9f9f9;
  }

  .ant-menu-item-active a,
  .ant-menu-item-selected a:hover {
    color: #4a4a4a;
    font-weight: bolder;
  }

  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: #4a4a4a;
    font-weight: bolder;
  }

  .sub-sider-menu-item a:hover,
  .ant-menu-item-selected.sub-sider-menu-item a:hover {
    color: @primary-color;
  }

  .ant-menu-item:hover {
    color: #4a4a4a;
    font-weight: bolder;
  }

  .ant-menu-item-selected {
    background-color: #efeff0;
  }

  .ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
    color: #4a4a4a;
    font-weight: bolder;
  }
}

.sider-menu {
  line-height: 30px;
  height: 30px;
}

.ant-menu-item-selected {
  font-weight: bolder;
}

.bottom-menus {
  .ant-menu-item-selected {
    background-color: transparent !important;
    font-weight: normal;
  }
}

.ant-menu-light.bottom-menus {
  .ant-menu-item-selected > a {
    font-weight: normal !important;
  }
}
.ant-menu-dark.bottom-menus {
  .ant-menu-item-selected > a {
    color: rgba(255, 255, 255, 0.65);
    font-weight: normal !important;
  }
}

.sub-sidebar {
  width: 20rem;
  border-right: 1px solid #e6e6e6;
  padding: 0 30px;
  overflow-x: hidden;
  .ant-layout-sider-children {
    height: auto;
  }
  .sub-title {
    font-size: 16px;
    padding: 32px 0;
    font-weight: 700;
    height: 85px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; //超出部分以省略号显示
    white-space: nowrap;
  }

  .sub-sider-menu {
    font-size: 14px;
    position: relative;
    max-height: calc(100% - 200px);
    overflow-y: auto;
    overflow-x: hidden;

    li.ant-menu-item {
      padding-left: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis; //超出部分以省略号显示
      white-space: nowrap;
      margin: 6px 0;
    }

    .ant-menu-item-active a,
    .ant-menu-item-selected a:hover {
      color: #4a4a4a;
      font-weight: bolder;
    }

    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover {
      color: #4a4a4a;
      font-weight: bolder;
    }

    .sub-sider-menu-item a:hover,
    .ant-menu-item-selected.sub-sider-menu-item a:hover {
      color: @primary-color;
    }

    .ant-menu-item:hover {
      color: #4a4a4a;
      font-weight: bolder;
    }

    .ant-menu-item-selected {
      background-color: transparent;
    }

    .ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
      color: #4a4a4a;
      font-weight: bolder;
    }
  }

  .sub-sider-footer {
    bottom: 0;
    position: absolute;
    margin-bottom: 20px;
  }
}

.sub-header {
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  position: fixed;
  padding: 0;
  z-index: 9;
  left: 350px;
  right: 0;
  height: 88px;
}

.sub-content {
  background: #fff;
  // height: 100vh;
  height: 100%;
  padding: 128px 50px 30px;
}

.primary {
  color: @primary-color;
}

.iconicon_success,
.iconicon_error {
  margin-right: 11px;
  font-size: 14px;
}

.message-success .ant-message-notice-content {
  border: 1px solid #c3e6cb;
  color: #2f732f;
  box-shadow: none;
  // background-color: #d4edda;
  background-color: #ffffff;
}

.message-error .ant-message-notice-content {
  border: 1px solid #f5c6cb;
  color: #b0341e;
  box-shadow: none;
  // background-color: #f8d7da;
  background-color: #ffffff;
}

.message-warning .ant-message-notice-content {
  border: 1px solid #bea049;
  color: #965f10;
  box-shadow: none;
  // background-color: #fff3cd;
  background-color: #ffffff;
  padding-left: 12px;
}

.icon-btn {
  cursor: pointer;
}

.link-btn {
  color: @primary-color;
  padding: 0;
}

.black-btn,
.black-btn:hover {
  background: #9b9b9b;
  border-color: #9b9b9b;
  font-size: 12px;
  color: #ffffff;
}

.tab-btn,
.tab-btn:active,
.tab-btn:hover {
  padding: 0;
  font-size: 14px;
  color: #9b9b9b;
}

.tab-btn:active,
.tab-btn:focus {
  padding: 0;
  font-weight: bolder;
  //color: #000000;
}

.link-btn-default,
.link-btn-default:hover,
.link-btn-default:active,
.link-btn-default:focus,
.link-btn-default::after {
  color: #757575;
  padding: 0;
  border: none;
  background-color: transparent;
}

.primary-default-btn,
.primary-default-btn:hover,
.primary-default-btn:focus,
.primary-default-btn:active {
  color: @primary-color;
  border-color: rgba(26, 167, 125, 0.2);
}

.dange-default-btn,
.dange-default-btn:hover,
.dange-default-btn:focus,
.dange-default-btn:active {
  color: @error-color;
  border-color: rgba(205, 41, 42, 0.2);
}

/*
 * @Author: melon
 * @Date: 2020-06-28 20:22:36 
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-22 11:21:23
 */

body {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
}

p {
  padding: 0;
  margin: 0;
}

.padding_normal {
  padding: 65px 100px;
}

// 列表总数
.Inf_total_right {
  padding: 16px 0;
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #828282;
}

// button

.Inf_text_btn {
  font-size: 12px;
  color: #9b9b9b;
  text-align: center;
  line-height: 18px;
}

// product card
.Inf_product_card_title {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: bold;
}

.Inf_product_card_remarks {
  font-size: 13px;
  color: #9b9b9b;
  font-weight: normal;
}

// 普通title
.Inf_page_title {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: bold;
}

// 普通说明文本
.Inf_page_text {
  font-size: 12px;
  color: #9b9b9b;
  margin-top: 5px;
}

// 弹框取消样式
.footerCanceltext {
  // font-size: 12px;
  color: #9b9b9b;
  // margin-top: 5px;
  border: none;
  box-shadow: none;
}

// 弹框二级说明样式
.lightboxL2title {
  font-size: 12px;
  color: #757575;
  position: relative;
  top: -25px;
}

// 加粗字体
.Inf_font_bold {
  font-weight: bold;
}

// 颜色为 蓝色 的url
.Inf_page_blue_url {
  font-size: 12px;
  color: #40a9ff;
  letter-spacing: 0;
  line-height: 17px;
  display: inline-block;
  cursor: pointer;
}

// 字体颜色和边框均为 @primary-color 背景颜色为白色
.Inf_default_btn {
  color: @primary-color;
  letter-spacing: 0;
  line-height: 17px;
  display: inline-block;
  cursor: pointer;
  border: 1px solid @primary-color;
  background-color: #fff;
  margin: 14px 0;
}

// 背景颜色和边框均为 @primary-color 字体颜色 为白色
.Inf_primary_btn {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0;
  line-height: 17px;
  display: inline-block;
  cursor: pointer;
  border: 1px solid @primary-color;
  background-color: @primary-color;
}

// 背景颜色和边框均为 #4a4a4a 字体颜色 为白色
.Inf_black_btn,
.Inf_black_btn:hover,
.Inf_black_btn:focus,
.Inf_black_btn:active {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0;
  line-height: 17px;
  display: inline-block;
  cursor: pointer;
  border: 1px solid #4a4a4a;
  background-color: #4a4a4a;
}

// page页面容器
.Inf_page {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.ant-select-arrow {
  margin-right: 10px;
}

/*>=1024px的设备*/
@media (min-width: 1024px) {
}

/*>=1100px的设备*/
@media (min-width: 1100px) {
}

/*>=1280的设备*/
@media (min-width: 1280px) {
}

/*>=1366的设备*/
@media (min-width: 1366px) {
}

/*>=1440px的设备*/
@media (min-width: 1440px) {
}

/*>=1680px的设备*/
@media (min-width: 1680px) {
}

/*>=1920px的设备*/
@media (min-width: 1920px) {
}

/*-------antd样式覆盖 --------*/

.ant-form-item-label > label.ant-form-item-required::before {
  content: none;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  content: '*';
  display: inline-block;
  margin-left: 4px;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 1;
  font-family: SimSun,sans-serif;
}
.ant-form-item.hiddenStar .ant-form-item-label > label.ant-form-item-required{
  &:after{
    display: none !important;
  }
}

.ant-menu > .ant-menu-item-divider {
  margin: 15px 0;
}

.ant-input-group {
  .ant-input {
    border-right: none;
  }

  .ant-input-group-addon {
    background-color: #fff;
    padding: 0;
  }
}

// 进度条
.ant-progress-inner,
.ant-progress-success-bg,
.ant-progress-bg {
  border-radius: 0;
}

// sider menu
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

// antd table th 表头
.ant-table-thead > tr > th {
  background: transparent; // 去掉背景色
  font-size: 12px;
  color: #9b9b9b; //
  border-top: 1px solid #efefef;
  padding: 4px 16px;
  line-height: 18px;
}

// antd table td 表格单元格
// .ant-table-tbody > tr > td {
//   font-size: 14px;
//   color: #4a4a4a;
// }

.ant-table-tbody > tr > td:first-child,
.ant-table-thead > tr > th:first-child {
  padding-left: 0;
}

// 去掉antd table hover 时候的背景色
.ant-table-tbody>tr.ant-table-row:hover>td,
// 去掉antd table 选中 时候的背景色
.ant-table-tbody>tr.ant-table-row-selected>td {
  background-color: #fff;
}

// 分页 活动页码背景色
.ant-pagination-item-active,
.ant-pagination-item-active a,
.ant-pagination-item-active a:hover,
.ant-pagination-item-active a:focus,
.ant-pagination-item-active a:visited,
.ant-pagination-item-active a:active {
  background-color: @primary-color;
  color: #fff !important;
}

// 去掉弹窗头部下边框
.ant-modal-header {
  border-bottom: none;
}

// 去掉弹窗尾部上边框
.ant-modal-footer {
  border-top: none;
}

.ant-modal-header,
.ant-modal-body,
.ant-modal-footer {
  padding: 0 40px;
}

.ant-modal-header {
  padding-top: 30px;
}

.ant-modal-body {
  padding-top: 30px;
  padding-bottom: 20px;
}

.ant-modal-footer {
  padding-top: 10px;
  padding-bottom: 36px;

  button {
    width: 100px;
    height: 40px;
  }
}

.ant-modal-content {
  .ant-modal-close {
    top: 18px;
    right: 26px;
    font-weight: normal;

    .ant-modal-close-icon {
      svg {
        display: none;
      }
    }

    .ant-modal-close-x {
      width: 46px;
      height: 46px;
      line-height: 46px;
    }

    .ant-modal-close-x:before {
      font-family: 'iconfont' !important;
      font-size: 28px;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e62c';
      // font-weight: bold;
    }
  }
}

// menu行高
.ant-menu-inline > .ant-menu-item {
  height: 30px;
  line-height: 30px;
}

// input select 长度
// pageHeader
.ant-page-header-content {
  padding: 0;
}

// Popover 无箭头
.noArrow {
  .ant-popover-arrow {
    display: none;
  }
}

// 长度240px
.Inf_normal_input_240,
.Inf_normal_select_240 {
  width: 240px;
}

// 长度285
.Inf_normal_input_285,
.Inf_normal_select_285 {
  width: 285px;
}

// 长度350
.Inf_normal_input_350,
.Inf_normal_select_350 {
  width: 350px;
}

// 长度420px

.Inf_large_input_420,
.Inf_large_select_420 {
  width: 420px;
}

// 长度500px
.Inf_large_input_500,
.Inf_large_select_500 {
  width: 500px;
}

// 长度541px
.Inf_large_input_541,
.Inf_large_select_541 {
  width: 541px;
}

// 去掉select的圆角
.ant-dropdown-menu,
.ant-select-dropdown {
  border-radius: 0;
}

// .ant-btn-primary {
//   font-weight: 500;
// }
.ant-btn {
  font-weight: 500;
}

//Antd自带Image组件隐藏图片预览顶部操作栏
.ant-image-preview-operations-operation,
.ant-image-mask {
  display: none;
}
.ant-image-preview-img {
  padding: 50px;
}
// 购物车数量显示角标
.ant-badge-count {
  min-width: 16px;
  height: 16px;
  font-size: 10px;
  line-height: 16px;
  padding: 0 3px;
  transform: translate(40%, -40%);
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;