.myOrder {
  .controlBar {
    color: #9b9b9b;
    margin: -10px 0 20px;
    :global .iconfont {
      font-size: 12px;
      transform: scale(0.8);
    }
  }

  :global .ant-table-tbody>tr>td:last-child {
    padding-right: 0;
  }

  :global .ant-table-tbody > tr > td {
    vertical-align: top;
  }

}
// .settingsMenu{
//   padding: 10px 20px;
//   background-color: #fff;
//   box-shadow: 0 0 3px 0 #aaacb1;
//   :global ul {
//     border-right: none;
//   }
//   :global li {
//     margin-bottom: 0px !important;
//     margin-top: 0px !important;
//     line-height: 30px !important;
//     height: 30px !important;
//     background-color: #fff !important;
//     padding: 0px !important;
//     a {
//       color: #4a4a4a;
//       font-weight: normal;
//     }
//   }
//   :global .ant-menu > .ant-menu-item-divider {
//     height: 1px !important;
//     margin: 6px 0 !important;
//     background-color: #f0f0f0 !important;
//   }
// }

.orderList{
  // :global .ant-table-tbody>tr:first-child {
  //   border-top: 1px solid #EFEFEF;
  // }
  .subscription,.product,.service{
    color: #9B9B9B;
    .orderInfo{
      margin-bottom: 10px;
      font-size: 13px;
      font-weight: 500;
      span {
        padding-left: 10px;
      }
    }
    .orderImg{
      display: flex;
      justify-content: flex-start;
      img{
        width: 100px;
        height: 100px;
        background: #e1e1e1;
        margin-right: 15px;
      }
    }
    .name{
      font-weight: bold;
      font-size: 14px;
      color: #4A4A4A;
      padding-bottom: 5px;
    }
    .subInfo {
      font-size: 13px;
      padding-top: 5px;
    }
    .pdImage{
      display: flex;
      justify-content: flex-start;
      img{
        width: 100px;
        height: 100px;
        background: #e1e1e1;
        margin-right: 15px;
        object-fit: cover;
      }
    }
    .child{
      >div:not(:last-child){
        padding-bottom: 15px;
        // border-bottom: 1px solid #EFEFEF;
      }
    }
  }
}
.priceBlock {
  visibility: hidden;
  margin-bottom: 10px;
}
.orderPrice{
  font-weight: bold;
  text-align: left;
  padding-top: 5px;
}
.logisticSelf{
  color: #fff;
  padding: 2px 6px;
  margin-right: 10px;
  font-size: 11px;
  border-radius:9px;
  background: #F0A845;
  cursor: pointer;
}
.status {
  color: #9B9B9B;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}
.actions{
  text-align: center;
  button{
    margin-top: 5px;
    width: 100px;
  }
  .btnPay {
    color: #1AA77D;
    background: #fff;
    border: 0.5px solid rgba(26, 167, 125, 0.6);
    border-radius: 2px;
    box-shadow: 0 0 1px rgba(26, 167, 125, 0.6);
    margin-bottom: 5px;

  }
}
.pagerPositon{
  text-align: center;
  margin: 30px 0 30px !important;
}
.sortMenu {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
  .titleName {
    color: #9b9b9b !important;
    font-size: 11px;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;