 .Web_Setting_Form_Wrapper {
  .Web_Setting_Form {

    // :global .ant-form-item-label {
    //   font-weight: bold;
    //   color: #4a4a4a;
    //   font-size: 14px;
    // }
    
    :global .ant-select-arrow {
      right: 8px;
      :global .iconfont {
        font-size: 12px;
      }
    }

    .form_Item_col {
      display: flex;
    }

    .setting_switch_wrapper {
      width: 200px;

      .setting_switch {
        display: inline-block;
      }

      .setting_text_form_item {
        display: inline-block;


      }

      .setting_text {
        display: inline-block;

        margin-left: 20px;
      }
    }

    .show_title_label {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .index_type_opt {
        .default_index {
          font-size: 12px;
          color: #9B9B9B;
          text-align: right;
          display: inline-block;
          cursor: not-allowed;
        }

        .can_opt_index {
          cursor: pointer;
          font-size: 12px;
          color: #1AA77D;
          text-align: right;
          display: inline-block;
        }
      }
    }

    .index_type_opt {
      cursor: pointer;
    }



  }

  :global .ant-form-vertical .ant-form-item-label>label,
  :global .ant-col-24.ant-form-item-label>label,
  :global .ant-col-xl-24.ant-form-item-label>label {
    width: 100%;
  }

  :global .ant-select-arrow {
    right: 8px;
    :global .iconfont {
      font-size: 12px;
    }
  }

}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;