.content {
  overflow-x: hidden;
  height: calc(100vh - 88px);
  padding-top: 0;
  .topArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    right: 50px;
    left: 400px;
    z-index: 30;
    background-color: #fff;
    top: 88px;
    padding-top: 25px;
    padding-bottom: 10px;
    .imgCount {
      color: #9b9b9b;
    }
  }
  .photoItem {
    // margin-bottom: 35px;
    // margin-top: 35px;
    cursor: pointer;
    :global .ant-typography-ellipsis-multiple-line {
      -webkit-line-clamp: 2;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */
    }
    .photo {
      width: 100px;
      height: 100px;
      border: 1px solid #e1e6e4;
      //position: relative;
      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .imgMessage {
        position: absolute;
        //z-index: 9;
        bottom: 53px;
        left: 18px;
        .commented {
          font-size: 10px;
          color: #ffffff;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 3px 4px 1px 4px;
          border-radius: 50%;
          line-height: 1;
          :global .iconfont {
            font-size: 10px;
          }
        }
      }
    }
    .selected {
      border: 1px solid @primary-color;
      border-radius: 2px;
      border-radius: 2px;
    }
    .photoName {
      width: 100px;
      height: 30px;
      text-align: center;
      word-break: break-all;
      font-size: 11px;
      line-height: 14px;
      padding-top: 6px;
      padding-bottom: 10px;
      color: #9b9b9b;
      -webkit-line-clamp: 2;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */

    }
    .flex{
      display: flex;
      justify-content: flex-start;
      .message{
        font-size: 14px;
        color: #4A4A4A;
        margin-left: 20px;
        line-height: 24px;
        .fileInfo{
          color: #9B9B9B;
          padding-bottom: 10px;
          font-size: 13px;
        }
        .subtext{
          color: #9B9B9B;
          font-size: 12px;
        }
        .textBlock{
          .textMessage{
            line-height: 20px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .pagination {
    width: 100%;
    text-align: center;
  }
}
.moreMenu {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}
.exportTextarea{
  font-size: 13px !important;
  height: 120px !important;
  border: 1px solid #E1E6E4;
  border-radius: 3px;
  background: #F2F2F2!important;
}
.header{
  //height: 120px!important;
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;