/*
 * @Author: melon 
 * @Date: 2020-06-28 21:11:55 
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-04 18:38:58
 */
.business_form {
  max-width: 1000px;
  min-width: 600px;

  :global .ant-select-arrow {
    right: 8px;
    :global .iconfont {
      font-size: 12px;
    }
  }
  :global .ant-form-item-label {
    font-weight: bold;
  }
  :global .ant-divider-horizontal {
    margin: 0 0 25px 0;
  }

  .Inf_normal_input,
  .Inf_normal_select {
    width: 285px;
  }
  .Inf_flexmax_input {
    width: 80%;
  }

  // .Inf_large_input {
  //   width: 100%;
  // }
  .Inf_large_select {
    width: 541px;
  }

  .Inf_small_select {
    width: 90px;
  }

  .Inf_ant_form_item {
    display: inline-block;
    margin-right: 20px;
  }

  .wxUpload {
    :global .logoUpload {
      button {
        top: 45%;
        margin-top: -16px;
      }

      :global .ant-upload.ant-upload-select-picture-card > .ant-upload {
        display: inline-block;
        padding: 0;
        width: 150px;
        height: 150px;
      }

      :global .ant-upload-select-picture-card {
        border: none;
        width: 150px;
        height: 150px;
        background-color: #f7f7f7;
      }

      :global .ant-upload-list-picture-card-container {
        width: 150px;
        height: 150px;
        padding: 0;
      }

      :global .ant-upload-list-picture-card .ant-upload-list-item {
        width: 150px;
        height: 150px;
        padding: 0;
      }

      .img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border: 1px solid #eee;
      }

      .uploadCover {
        width: 150px;
        height: 150px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .description {
    font-size: 12px;
    color: #9b9b9b;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;