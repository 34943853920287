.titleBar {
  padding: 24px 50px 23px !important;
  border-bottom: 1px solid #e6e6e6;
  .backBtn {
    width: 28px;
    height: 22px;
    line-height: 20px;
    padding: 0;
    margin-right: 10px;
    :global .iconfont {
      font-size: 12px;
    }
  }
  .normalBtn {
    color: #1AA77D;
    background: #fff;
    border: 1px solid rgba(26, 167, 125, 0.6);
    border-radius: 2px;
    box-shadow: 0 0 1px rgba(26, 167, 125, 0.6);
  }
}
.title,.blockTitle{
  font-size: 14px;
  color: rgba(74, 74, 74, 1);
  font-weight: 600;
  margin: 50px 0 10px;
}
.infos{
  // font-size: 12px;
  color: rgba(74, 74, 74, 1);
  .title{
    font-size: 12px;
    color: rgba(74, 74, 74, 0.8);
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .client,.abstract{
    >div{
      line-height: 24px;
      font-weight: 500;
      // line-height: 24px;
    }
    .mark{
      padding-top: 48px;
    }
    .logisticsInfoVo{
      display: flex;
      justify-content: flex-start;
      div {
        span {
          color: #DCDCDC;
          margin-right: 10px;
        }
        .edit {
          color: #066DD5;
          cursor: pointer;
        }
      }
    }
    .toBuyAccount {
      display: flex;
      justify-content: flex-start;
      .splitLine {
        color: #DCDCDC;
        margin: 0 10px;
      }
      .sendOrderInfo {
        color: #066DD5;
        cursor: pointer;
      }
    }
  }
}
.orderPage {
  overflow-x: hidden;
  height: calc(100vh - 88px);
  padding: 30px 50px;
  background: #fff;
  .noInfo {
    // font-style: italic;
    font-size: 13px;
  }
}
// .payment {}
.productName{
  display: flex;
  img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 5px;
  }
  >div {
    flex: 1;
    padding-left: 10px;
    position: relative;
    .productName {
      font-size: 14px;
      font-weight: 600;
    }
    .productDesc {
      font-size: 12px;
      color: #9b9b9b;
    }
    .logisticAddition{
      position: absolute;
      bottom: 0;
      // left: 10px;
      display: flex;
      justify-content: flex-start;
      span {
        background: #4a4a4a;
        color: #fff;
        padding: 2px 6px;
        margin-right: 10px;
        font-size: 11px;
        // -webkit-transform: scale(0.9);
        // display: inline-block;
        border-radius:9px;
      }
      .logisticSelf {
        background: #C1502A;
      }
      .noneedLogistic {
        background: #BFA97A;
      }
    }
  }
}
.priceList{
  >div{
    display: flex;
    justify-content: space-between;
    &.totalPrice {
      font-size: 16px;
      font-weight: bold;
      margin-top: 20px;
    }
    span{
      width: 50%;
      text-align: right;
    }
  }
}
.blockTitle{
  border: 1px solid #EFEFEF;
  border-left: none;
  border-right: none;
  // border-top: none;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  .btnIcon{
    font-size: 13px;
    width: 30px;
    text-align: right;
    cursor: pointer;
  }
}
.makeInfo{
  // font-size: 12px;
  // color: #4A4A4A;
  img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 5px;
    margin-bottom: 20px;
  }
}
.orderRecord{
  font-size: 12px;
  padding-top: 10px;
  line-height: 24px;
  span{
    color: #DCDCDC;
    margin-left: 10px;
    &.edit{
      color: #066DD5;
      cursor: pointer;
    }
  }
  .logImgs{
    img{
      width: 50px;
      height: 50px;
      object-fit: cover;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}

.createDialog {
  // .Inf_ant_form_item {
  //   display: inline-block;
  //   margin-right: 20px!important;
  //   .Inf_Province_Select_Cascader--bqWNm {
  //     .ant-select {
  //       margin-right: 10px !important;
  //     }
  //   }
  // }
  :global .ant-select-arrow {
    right: 8px;
    :global .iconfont {
      font-size: 12px;
    }
  }
}
.newButton{
  position: fixed;
  top: 24px;
  right: 50px;
  height: 40px;
  line-height: 40px;
  z-index: 100;
  button{
    margin-left: 12px;
  }
}
.ant-layout-content{
  position: absolute;
  padding: 0 50px;
}
:global .ant-table {
  .price4Right {
    text-align: right;
    padding-right: 0;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;