/*
 * @Author: melon 
 * @Date: 2020-07-18 13:41:39 
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-18 17:28:59
 */
.web_about {
  width: 100%;
  // border: 1px solid #E1E1E1;
  // padding: 25px;
  :global textarea.ant-input {
    border: none;
  }

  :global .ant-form-item {
    margin-bottom: 10px;
  }

  :global .ant-form-vertical .ant-form-item-label,
  :global .ant-col-24.ant-form-item-label,
  :global .ant-col-xl-24.ant-form-item-label {
    padding: 0;
    height: 35px;
  }

  :global .ant-input {
    padding: 0;
  }
}

.title,
textarea.title {
  font-size: 20px;
  color: #4a4a4a;
  font-weight: bold;
}

.about_text {
  font-size: 14px;
  color: #4a4a4a;
}

.align_bth_wrapper {
  display: inline-block;
}

span.align_btn {
  display: inline-block;

  margin: 0 5px;
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;