.myProject {
  .controlBar {
    color: #9b9b9b;
    margin: -10px 0 20px;
  }

  // :global .ant-table-tbody>tr>td:last-child {
  //   padding-right: 0;
  // }
  :global .ant-card-meta {
    margin: 0;
    display: flex;
    align-items: center;
  }
  :global .ant-card-meta-avatar {
    padding-right: 15px;
  }
  
  :global .ant-avatar {
    background: #e1e1e1;
  }
  :global .ant-avatar-square {
    border-radius: 0px;
  }

}

.tableOpt{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  >span{
    display: flex;
    align-items: center;
    margin-left: 50px;
    cursor: pointer;
  }
  .btnAdd2Cart {
    color: #1AA77D;
    background: #fff;
    border: 0.5px solid rgba(26, 167, 125, 0.6);
    border-radius: 2px;
    box-shadow: 0 0 1px rgba(26, 167, 125, 0.6);
  }
}
.settingsMenu{
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}
:global .shareText{
  font-size: 12px;
  font-weight: bold;
  color: #9b9b9b;
  padding-bottom: 20px;
  text-align: center;
  >div{
    width: 100%;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background: #EFEFEF;
    border: 1px solid #E1E6E4;
    margin-bottom: 30px;
  }
}
.pagerPositon{
  text-align: center;
  margin: 30px 0 30px !important;
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;