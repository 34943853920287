/*
 * @Author: melon 
 * @Date: 2020_06_28 20:26:25 
 * @Last Modified by: melon
 * @Last Modified time: 2020_06_28 20:41:39
 */
.register_page {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	// 左边内容提醒框
	.register_show_info_container {
		flex: 1;
		background-image: linear-gradient(137deg, #EEEEEE 0%, #D8D8D8 100%);
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;


		.left_header {
			.welcome {
				font-size: 18px;
				color: #131415;
			}

			.brands {
				color: #131415;

				img {
					width: 164px;
					height: 28px;
				}
			}

			.brands_text {
				width: 480px;
				margin-top: 130px;
				font-size: 28px;
				color: rgba(0, 0, 0, 0.85);

			}
		}

		.left_content {
			width: 480px;
			margin-top: 130px;

			.user_talk_text {
				width: 480px;
				color: #757575;
			}

			.user_info {
				width: 480px;
				margin-top: 40px;

				.code_img {
					width: 48px;
					height: 48px;
					margin-right: 15px;
				}

				.user_text_wrapper {
					display: inline-block;

					span {
						display: inline-block;
					}

					span.user_name {
						margin-right: 15px;
					}
				}
			}
		}


	}

	//  右边登录表单
	.register_form_container {
		flex: 1;
		height: 100%;



		:global .ant-spin-container,
		:global .ant-spin-nested-loading {
			height: 100%;
		}
	}

}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;