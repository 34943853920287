/*
 * @Author: melon 
 * @Date: 2020-07-02 11:00:07 
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-12 00:27:02
 */
.service_wrapper {
  max-width: 1000px;
  min-width: 600px;

  .Title {
    font-size: 14px;
    color: #4A4A4A;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .add_btn {
    color: #1AA77D;
    background: #fff;
    border: 1px solid rgba(26, 167, 125, 0.6);
    border-radius: 2px;
    box-shadow: 0 0 1px rgba(26, 167, 125, 0.6);
  }
  :global .Inf_product_card--GSMbJ .Inf_product_card_content--PBCiV{
    margin-left: 6px;
  }
  :global .ant-divider-horizontal {
    margin: 25px 0 0 0;
  }
}

.service_item_opt {
  button.service_item_btn {
    //border: none;
    height: 18px;
    font-size: 13px;
    color: #9B9B9B;
    //width: 35px;
    text-align: center;
    padding: 0;
  }
}
.remarkTip {
  justify-content: center;
  display: flex;
  text-align: center;
  line-height: 18px;
  padding-top: 10px;
  margin-bottom: 20px;
  color: #757575;
  font-size: 13px;
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;