.bottomNotice {
  position: absolute;
  right: calc(50% - 350px);
  bottom: 35px;
  background-color: #ffffff;
  z-index: 3000;
  padding: 0;
  .bottomNoticeContent {
    border: 1px solid #E1E1E1;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    .fontBord {
      font-weight: bolder;
    }
    .desc {
      font-size: 13px;
      color: #757575;
      .descIcon {
        font-size: 13px;
        color: #757575;
        margin-right: 10px;
      }
    }
    .btns {
      margin-left: 8px;
      .linkBtn {
        font-size: 13px;
      }
    }
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;