.titleBar {
  padding: 24px 50px 23px !important;
}
.createProjectDialog {
  position:relative;
  :global .ant-select-arrow {
    right: 8px;
    :global .iconfont {
      font-size: 12px;
    }
  }
  :global .ant-form-item textarea.ant-input{
    height: 160px;
  }
}
.storeSetsForm {
  margin-top: 20px !important;
  :global .ant-table-tbody > tr > td {
    padding: 16px 0 16px;
  }
  :global .ant-table-thead > tr > th {
    padding: 4px 0;
  }
  :global .ant-form-item-label {
    font-weight: bold;
  }
  :global .ant-select-arrow {
    right: 8px;
    :global .iconfont {
      font-size: 12px;
    }
  }
  .switchLabel {
    margin-left: 12px;
  }
  .menuBtn {
    text-align: right;
  }
  .add_btn {
    color: #1AA77D;
    background: #fff;
    border: 1px solid rgba(26, 167, 125, 0.6);
    border-radius: 2px;
    box-shadow: 0 0 1px rgba(26, 167, 125, 0.6);
  }

  .desc {
    font-size: 12px;
    color: #9b9b9b;
    padding-top: 10px;
    font-weight: normal;
    line-height: 18px;
  }

  .baseForm {
    max-width: 800px;
    min-width: 600px;
    .productContent{
      :global .ant-form-item-extra{
        font-size: 12px;
        color: #9b9b9b;
        padding-top: 10px;
        font-weight: normal;
        line-height: 18px;
      }
    }
  }

  .shipForm {
    max-width: 800px;
    min-width: 600px;
  }

  .categoryForm {
    max-width: 800px;
    min-width: 600px;
  }

  .policyForm {
    max-width: 800px;
    min-width: 600px;

  }

  // :global .ant-checkbox {
  //   // checkbox 样式
  //   :global .ant-checkbox-inner {
  //     border-radius: 50%;
  //     border-color: #cfd1d7;
  //   }
  // }

  // :global .ant-checkbox-checked .ant-checkbox-inner {
  //   background-color: @primary-color;
  //   border-color: @primary-color;
  // }
}
.settingsMenu {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;