.content {
  overflow-x: hidden;
  height: calc(100vh - 88px);
  padding-top: 0;
  // height: 200px;
  // overflow: auto;
  // background-color: red;
  .topArea {
    display: flex;
    justify-content: space-between;
    position: fixed;
    right: 50px;
    left: 400px;
    z-index: 30;
    background-color: #fff;
    top: 88px;
    padding-top: 25px;
    padding-bottom: 10px;
    .imgCount {
      color: #9b9b9b;
      padding-top: 6px;
      display: inline-block;
    }
  }
  .photoItem {
    cursor: pointer;
    :global .ant-typography-ellipsis-multiple-line {
      -webkit-line-clamp: 2;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */
    }
    .photo {
      width: 130px;
      height: 130px;
      border: 1px solid #e1e6e4;
      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .selected {
      border: 1px solid @primary-color;
      border-radius: 2px;
      border-radius: 2px;
    }
    .photoName {
      width: 130px;
      height: 48px;
      text-align: center;
      word-break: break-all;
      font-size: 11px;
      line-height: 14px;
      padding-top: 6px;
      padding-bottom: 10px;
      color: #9b9b9b;
    }
  }

  .pagination {
    width: 100%;
    text-align: center;
  }
}
.remarkTip {
  justify-content: center;
  display: flex;
  text-align: center;
  line-height: 18px;
  padding-top: 10px;
  color: #757575;
  font-size: 13px;
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;