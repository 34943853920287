.code_img{
  width: 180px;
  height: 180px;
  padding: 10px 10px 0 10px;
}
.code_text{
  color:#cccccc;
  text-align: center;
  font-size: 12px;
  margin:0;
  padding:0 0 6px 0;
}
.code_item{
  padding:0 0 !important;
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;