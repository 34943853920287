/*
 * @Author: melon 
 * @Date: 2020-07-03 10:34:24 
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-18 17:28:50
 */

.account_form_wrapper {
  max-width: 800px;
  min-width: 600px;
  :global .ant-card-body {
    padding: 0 0 20px 0;
  }
  :global .ant-select-arrow {
    right: 8px;
    :global .iconfont {
      font-size: 12px;
    }
  }
  :global .ant-form-item-label {
    font-weight: bold;
  }

  .account_form {
    .user_btn_wrapper {

      .upload_btn,
      .reset_psw_btn {
        display: inline-block;
        color: #1AA77D;
        background: #fff;
        border: 1px solid rgba(26, 167, 125, 0.6);
        border-radius: 2px;
        box-shadow: 0 0 1px rgba(26, 167, 125, 0.6);
      }

      .upload_btn {
        margin-right: 20px;
      }
    }
    :global .ant-divider-horizontal {
      margin: 10px 0 25px 0;
    }
    .Inf_normal_input,
    .Inf_normal_select {
      width: 285px;
    }
  }
}
.own_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    // .own_footer_text {
    //   line-height: 40px;
    //   font-size: 13px;
    //   color: #066DD5;
    //   cursor: pointer;
    // }
  }
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;