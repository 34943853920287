.inviteFooterP{
  display: flex;
  justify-content: space-between;
}
.span1{
  font-size: 14px;
  color: #4A4A4A;
  letter-spacing: 0;
  // font-weight: 600;
}
.span2{
  font-size: 12px;
  color: #9B9B9B;
  letter-spacing: 0;
  font-weight: 400;
}
.return{
  margin-top: 18px;
  font-size: 13px;
  color: #066DD5;
  letter-spacing: 0;
  font-weight: 400;
  cursor: pointer;
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;