body {
  margin: 0;
  /*font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;*/
  font-family: Avenir, Avenir Next, Segoe UI, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #4a4a4a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
