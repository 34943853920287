/*
 * @Author: melon 
 * @Date: 2020-07-16 16:19:40 
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-18 17:29:02
 */
.about_wrapper {
  max-width: 945px;
  min-width: 600px;
  white-space: pre-wrap;
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;