.formContrl {
  max-width: 1000px;
  min-width: 600px;
  :global .ant-form-item-label {
    font-weight: bold;
  }
  .switchLabel {
    margin-left: 12px;
  }
}
.description {
  color: #9b9b9b;
  margin-top: 6px;
  font-size: 12px;
}
.brandLogoUpload {
  position:relative;
  :global .logoUpload {
    button {
      top: 45%;
      margin-top: -16px;
    }
    :global .ant-upload.ant-upload-select-picture-card > .ant-upload {
      display: inline-block;
      padding: 0;
      width: 250px;
      height: 150px;
    }
    :global .ant-upload-select-picture-card {
      border: none;
      width: 250px;
      height: 150px;
      background-color: #f7f7f7;
    }
    :global .ant-upload-list-picture-card-container {
      width: 250px;
      height: 150px;
      padding: 0;
    }
    :global .ant-upload-list-picture-card .ant-upload-list-item {
      width: 250px;
      height: 150px;
      padding: 0;
    }
    .img {
      width: 250px;
      height: 150px;
      object-fit: contain;
    }
    .uploadCover {
      width: 250px;
      height: 150px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  .absolute{
    position: absolute;
    width: 250px;
    height: 150px;
    background: rgba(255,255,255,.5);
  }
}
.slicesLogoUpload {
  position:relative;
  button {
    top: 45%;
    margin-top: -16px;
  }
  :global .logoUpload {
    :global .ant-upload.ant-upload-select-picture-card > .ant-upload {
      display: inline-block;
      padding: 0;
      width: 150px;
      height: 150px;
    }
    :global .ant-upload-select-picture-card {
      border: none;
      width: 150px;
      height: 150px;
      background-color: #f7f7f7;
    }
    :global .ant-upload-list-picture-card-container {
      width: 150px;
      height: 150px;
      padding: 0;
    }
    :global .ant-upload-list-picture-card .ant-upload-list-item {
      width: 150px;
      height: 150px;
      padding: 0;
    }
    .img {
      width: 150px;
      height: 150px;
      object-fit: contain;
    }
    .uploadCover {
      width: 150px;
      height: 150px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  .absolute{
    position: absolute;
    width: 150px;
    height: 150px;
    background: rgba(255,255,255,.5);
  }
}
.websiteIcon{
  position: relative;
  .absolute{
    position: absolute;
    top: 77px;
    width: 60px;
    height: 32px;
    background: rgba(255,255,255,.5);
  }
}
.webIcon {
  background: #f7f7f7;
  width: 50px;
  height: 50px;
  border: none;
  margin-bottom: 1.67rem;
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
}
.webLayout {
  :global .ant-radio-button-wrapper {
    margin-right: 1.67rem;
    height: 60px;
    width: 120px;
    text-align: center;
    line-height: 60px;
  }
  :global
    .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid #eeeeee;
  }
  :global
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: rgba(0, 0, 0, 0.7);
  }
  .buttonCheckIcon {
    position: absolute;
    bottom: 0;
    right: 0;
    line-height: 16px;
    margin-bottom: 4px;
    margin-right: 4px;
  }
}
.address {
  .addressLabel {
    font-size: 14px;
    color: #4a4a4a;
    letter-spacing: 0;
  }
  .rename{
    font-size: 14px;
    color: #1AA77D;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 400;
    cursor: pointer;
    margin-left: 20px;
    padding-left: 15px;
    position: relative;
    &:after{
      position: absolute;
      content: '';
      height: 14px;
      width: 1px;
      top: 3px;
      left: 0;
      background: #EAEAEA;
    }
  }
}
.secondBtn{
  color: #9B9B9B;
}
.mTitle{
  font-size: 16px;
  color: #4A4A4A;
  line-height: 18px;
  font-weight: 300;
}
.addressP{
  display: flex;
  justify-content: space-between;
}
.webAdd{
  font-size: 14px;
  color: #4A4A4A;
  // font-weight: 600;
}
.webAdd2{
  font-size: 12px;
  color: #4A4A4A;
}
.absolute{
  position: absolute;
  left: 0;
  top: 0;
  width: 44px;
  height: 22px;
  cursor: not-allowed;
}


@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;