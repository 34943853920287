.titleBar {
  padding: 24px 50px 23px !important;
  border-bottom: 1px solid #e6e6e6;
  position: fixed !important;
  z-index: 9;
  left: 350px;
  right: 0;
}
.controlBar {
  position: fixed;
  top: 88px;
  left: 400px;
  right: 50px;
  z-index: 99;
  padding: 25px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border-top: 1px solid #e6e6e6;
  line-height: 0px !important;
  background-color: #fff;
  // position: fixed;
  .ptCount {
    color: #9b9b9b;
  }
  :global .iconfont {
    font-size: 12px;
    transform: scale(0.8);
  }
}
.search{
  float: right;
  width:240px!important;
  border: 1px solid #EFEFEF !important;
  padding: 6px 8px !important;
  background: #EFEFEF!important;
  input{
    background: transparent;
  }
  :global .iconfont {
    font-size: 16px;
    color: #9b9b9b;
  }
}
.searchs{
  float: right;
  width:240px!important;
  border: 1px solid #E1E1E1 !important;
  padding: 6px 8px !important;
  background: transparent!important;
  input{
    background: transparent;
  }
  :global .iconfont {
    font-size: 16px;
    color: #9b9b9b;
  }
  .clearInput {
    font-size: 12px;
    color: #4a4a4a;
    margin-right: 0px;
    cursor: pointer;
  }
}
.sortMenu {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
  .titleName {
    color: #9b9b9b !important;
    font-size: 11px;
  }
}
.orderList {
  //border-top: 1px solid #e6e6e6;
  padding: 175px 50px 20px !important;
  :global .ant-card-body {
    padding: 0 !important;
  }
  :global .ant-table-thead > tr > th{
    line-height: 20px;
  }
  :global .ant-table-tbody > tr > td {
    font-size: 14px;
    padding: 15px 16px;
  }
  .nameCell {
    cursor: pointer;
    font-weight: bold;
  }
}
.settingsMenu{
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}
:global .ant-table-pagination.ant-pagination {
  margin: 30px 0 !important;
}
.remarkTip {
  justify-content: center;
  display: flex;
  text-align: center;
  line-height: 18px;
  padding-top: 10px;
  color: #757575;
  font-size: 13px;
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;