.createProjectDialog {
  position:relative;
  .titleBar{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    p{
      span{
        cursor: pointer;
        color: #1AA77D;
      }
    }
  }
  .searchInput {
    margin: 5px 0;
  }
  :global .ant-list-bordered .ant-list-item {
    padding-left: 20px;
    padding-right: 20px;
  }
  :global .ant-form-item {
    margin: 5px 0;
  }
  :global .ant-checkbox {
    // checkbox 样式
    :global .ant-checkbox-inner {
      border-radius: 50%;
      // border-color: #cfd1d7;
    }
  }
  .options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .names {
      max-width: 280px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .assignedPrice {
      font-size: 13px;
      color: #9b9b9b;
      padding-left: 10px;
    }
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;