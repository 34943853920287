.dialogBox {
    .theIcon {
        text-align: center;
        .title {
            font-size: 16px;
            font-weight: bold;
            margin: 10px 0 20px 0;
        }
        .submitButton {
            width: 100px;
            height: 40px;
            line-height: 40px;
            margin: 30px auto 10px;
            background-color: #1AA77D;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
        }
        .remarkText {
            color: #AAACB1;
            font-size: 12px;
        }
    }
    :global div.ant-modal-footer {
        padding-bottom: 0px;
    }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;