.recycle_wrapper {
  max-width: 1000px;
  min-width: 600px;

  :global table {
    border-top: 1px solid #f0f0f0;
    .ant-table-thead {
      display: none;
    }
  }
  :global .ant-table-tbody > tr > td {
    padding: 16px 0 16px;
  }

  .deleteBtn {
    color: #E02020;
    background: #fff;
    border: 1px solid rgba(224, 32, 32, 0.6);
    border-radius: 2px;
    box-shadow: 0 0 1px rgba(224, 32, 32, 0.6);
    &:hover{
      color: #E02020;
      border: 1px solid rgba(224, 32, 32, 0.6);
    }
    &:focus{
      color: #E02020;
      border: 1px solid rgba(224, 32, 32, 0.6);
    }
  }
  :global .Inf_product_card--GSMbJ .Inf_product_card_content--PBCiV{
    margin-left: 6px;
  }
  :global .ant-divider-horizontal {
    margin: 25px 0 0 0;
  }
  .desc {
    font-size: 12px;
    color: #9b9b9b;
    padding-top: 10px;
    font-weight: normal;
    line-height: 18px;
  }
}


.files_cell {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 20px;
    width: 50px;
    height: 50px;
    object-fit: contain;
    //border-radius: 18px;
  }

  .name {
    letter-spacing: 0;
    line-height: 20px;
    font-weight: bold;
  }
  .info {
    font-size: 12px;
    color: #9B9B9B;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 400;
  }
}

.menuBtn {
  text-align: right;
}
.settingsMenu {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
  .menuBtn_red{
    color: #E02020;
    &:hover{
      color: #E02020;
    }
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;