.productsPage {
  overflow-x: hidden;
  height: calc(100vh - 88px);
  padding: 50px 50px;
  background: #fff;
}
.titleBar {
  padding: 24px 50px 23px !important;
  border-bottom: 1px solid #e6e6e6;
  .backBtn {
    width: 28px;
    height: 22px;
    line-height: 20px;
    padding: 0;
    margin-right: 10px;
    :global .iconfont {
      font-size: 12px;
    }
  }
}
.controlBar{
  position: fixed!important;
  width: calc(100vw - 450px);
  line-height: 30px;
  padding: 25px 0 10px 0;
  top: 88px;
  z-index: 200;
  background: #fff;
  .ptCount {
    color: #9b9b9b;
  }
  :global .iconfont {
    font-size: 12px;
    transform: scale(0.8);
  }
}
.sortMenu {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
  .titleName {
    color: #9b9b9b !important;
    font-size: 11px;
  }
}
.newProductBtn{
  position: fixed;
  top: 24px;
  right: 50px;
  height: 40px;
  line-height: 40px;
  z-index: 100;
  button{
    margin-left: 12px;
  }
}

.newProductMenu {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}

.products {
  .productCategory{
    line-height: 30px;
    margin: 20px 0 10px;
    font-size: 12px;
    color: rgba(74, 74, 74, 0.8);
    font-weight: bold;
  }
  .list{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .item{
      max-width: 300px;
      min-width: 200px;
      margin-bottom: 30px;
      @media (min-width: 768px){
        &.item{
          width:47%;
          margin-right: 3%;
        }
      }
      //@media (min-width: 992px){
      @media (min-width: 1092px){
        &.item{
          width:32%;
          margin-right: 1.333%;
        }
      }
      //@media (min-width: 1200px){
      @media (min-width: 1330px) {
        &.item {
          width: 23%;
          margin-right: 2%;
        }
      }
      //@media (min-width: 1440px){
      @media (min-width: 1920px){
        &.item{
          width:15%;
          margin-right: 1.666%;
        }
      }
      //@media (min-width: 1920px){
      //  &.item{
      //    width:23%;
      //    margin-right: 2%;
      //  }
      //}
      //@media (min-width: 2560px){
      //  &.item{
      //    width:16.6666%;
      //  }
      //}
      .img{
        width: 100%;
        position: relative;
        overflow: hidden;
        padding-bottom: 100%;
        .imgF{
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          background: #CFD1D7;
        }
        .imgBg{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .iconfont{
            display: inline-block;
            width: 20px;
            height: 20px;
          }
        }
        .selfFulfillment,.hidden,.operation{
          position: absolute;
        }
        .selfFulfillment{
          top: 8px;
          left: 8px;
          width: 64px;
          height: 24px;
          line-height: 25px;
          text-align: center;
          font-size: 12px;
          transform: scale(0.9,0.9);
          // color: #FFFFFF;
          // background: rgba(6,109,213,0.60);
          color: #000;
          background: rgba(255, 255, 255, 0.9);
          border-radius: 8px;
        }
        .hidden{
          right: 8px;
          bottom: 8px;
          width: 52px;
          height: 24px;
          line-height: 25px;
          text-align: center;
          font-size: 12px;
          transform: scale(0.9,0.9);
          color: #fff;
          background: rgba(0,0,0,0.60);
          border-radius: 8px;
        }
        .operation{
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(0,0,0,0.60);
          color: #fff;
          :global .ant-divider{
            top: 0.05em;
            height: 0.5em;
            border-color: #fff;
          }
          span{
            cursor: pointer;
          }
        }
      }
      .itemName{
        font-size: 14px;
        font-weight: bold;
        margin-top: 6px;
        line-height: 24px;
      }
      .itemPrice{
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
.createProjectDialog {
  .overflow {
    max-height: 800px;
    overflow: auto;
    .picture {
      position: relative;
      > div {
        img {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          margin-bottom: 10px;
          object-fit: cover;
        }
      }
      .productDesc {
        font-size: 12px;
        line-height: 18px;
        margin: 10px 0 40px;
        &.hide{
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .upDown{
        position: absolute;
        bottom: -20px;
        right: 0;
        cursor: pointer;
        color: rgb(6, 109, 213);
        font-size: 12px;
      }
    }
    .tableContent{
      border: #E1E1E1 0.5px solid;
      position: relative;
    }
    .tableContentBody{
      max-height: 500px;
      overflow-y: auto;
      padding: 0 15px;
    }
    .Ptitle, .content {
      display: flex;
      justify-content: left;
      border-bottom: 0.5px solid #E1E1E1;
      font-size: 12px;
      flex-wrap: wrap;
      .contents {
        position: relative;
        top: -13px;
        width: 100%;
        height: 30px!important;
      }
      &.Ptitle {
        color: #9B9B9B;
        line-height: 30px;
        margin: 0 15px;
        > div {
          text-align: right;
        }
      }
      &.content {
        font-size: 14px;
        // :global .iconfont {
        //   font-size: 14px;
        // }
        div {
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .opt {
        flex: 1;
        display: flex;
        justify-content: flex-start !important;
        font-weight: 500;
      }
      .addPages {
        flex: 1;
        display: flex;
        justify-content: flex-start !important;
        font-size: 13px;
        color: #757575;
      }

      .price {
        width: 120px;
      }

      .addPagePrice {
        width: 120px;
      }
      :global .ant-input-number{
        width: 100%;
      }
      .inputred{
        color:#CD292A;
        :global .ant-input {
          border-color: #CD292A;
        }
      }
      .shipping {
        width: 120px;
      }

      .isShow {
        width: 30px;
        margin-left: 20px;

        // .iconfont {
        //   cursor: pointer;
        //   font-size: 16px;
        // }
      }

      :global .ant-input {
        height: 28px;
        width: 80px;
        text-align: right;
      }
    }
  }
  .tips {
    font-size: 12px;
    color: #9b9b9b;
    margin-top: 10px;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;