.project {
  .projectImgs {
    width: 100%;
    max-width: 420px;
    max-height: 280px;
    min-width: 240px;
    min-height: 160px;
    /* border: 1px solid #e1e6e4; */
    background: #eeeeee;
    margin-bottom: 8px;
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .topArea {
      position: absolute;
      top: 0;
      z-index: 2;
      margin: 10px 0 0 10px;
      .lock {
        font-size: 14px;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 3px 3px 3px 3px;
        border-radius: 50%;
        line-height: 1;
      }
    }
  }
  .projectLabel {
    font-size: 14px;
    //color: #000000;
    font-weight: bolder;
    //限制标题长度并省略
    width: 100%;
    height: 22px;
    padding-right:30px;
    position: relative;
    .projectName{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .menu {
      display: none;
      position: absolute;
      right:0;
      top:0;
      z-index: 1;
    }
  }
  .remark {
    font-size: 12px;
    color: #9b9b9b;
    padding-bottom: 40px;
  }
  &:hover{
    .menu{
      display: inline-block;
    }
  }
}
.projectMenu {
  // position: relative;
  // margin: 0px;
  padding: 10px 20px;
  // text-align: left;
  // list-style-type: none;
  background-color: #fff;
  // background-clip: padding-box;
  // outline: none;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 36px !important;
    height: 36px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}
:global .grid{
  @media (min-width: 576px){
    .grid-item{
      width:100%;
    }
  }
  @media (min-width: 768px){
    .grid-item{
      width:100%;
    }
  }
  @media (min-width: 992px){
    .grid-item{
      width:50%;
    }
  }
  @media (min-width: 1200px){
    .grid-item{
      width:33.3333%;
    }
  }
  @media (min-width: 1440px){
    .grid-item{
      width:25%;
    }
  }
  @media (min-width: 1920px){
    .grid-item{
      width:20%;
    }
  }
  @media (min-width: 2560px){
    .grid-item{
      width:16.6666%;
    }
  }
  //:global .grid-item{
  //  width:100%;
  //}
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;