.createProjectDialog {
  position:relative;
  :global .ant-select-arrow {
    right: 8px;
    :global .iconfont {
      font-size: 12px;
    }
  }
  .more{
    position: absolute;
    bottom: -80px;
    color: #066DD5;
    font-size: 13px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  .btns{
    color: #1AA77D;
    cursor: pointer;
  }
}
.resultMain {
  font-size: 13px;
  // line-height: 16px;
}
.resultExtra {
  font-size: 12px;
  color: #9b9b9b;
  line-height: 14px;
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;