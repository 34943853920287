/*
 * @Author: melon 
 * @Date: 2020-07-16 16:19:40 
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-18 17:27:13
 */
.contact_wrapper {
  display: flex;

  .web_contact {
    width: 500px;
    // border: 1px solid #E1E1E1;
    //padding: 0px 6px;
    height: 475px;
  }

  .title {
    font-size: 20px;
    color: #4A4A4A;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .contact_text {
    font-size: 14px;
    color: #4A4A4A;
    // margin: 10px 0;
    margin-bottom: 2px;
  }

  .contact_wx_code {
    margin-top: 10px;
    :global .Inf_page_text {
      text-align: center;
      width: 150px;
    }

    img.image {
      width: 160px;
      height: 160px;
      object-fit: scale-down;
      margin-top: 10px;
      border: 1px solid #E1E1E1;
      border-radius: 2px;
      padding: 1px;
    }
  }

}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;