.projectSetsForm {
  :global .ant-form-item-label {
    font-weight: bold;
    color: #4a4a4a;
    font-size: 14px;
  }

  :global .ant-select-arrow {
    right: 8px;
    :global .iconfont {
      font-size: 12px;
    }
  }

  // :global .ant-form-item {
  //   margin-bottom: 0;
  // }
  // .project {
  //   .projectImgs {
  //     width: 240px;
  //     height: 160px;
  //     border: 1px solid #e1e6e4;
  //     background: #eeeeee;
  //     margin-bottom: 6px;
  //     .img {
  //       width: 100%;
  //       height: 100%;
  //       object-fit: cover;
  //     }
  //   }
  //   // .projectLabel {
  //   //   font-size: 14px;
  //   //   //color: #000000;
  //   //   font-weight: bolder;
  //   //   .menu {
  //   //     float: right;
  //   //   }
  //   // }
  //   .remark {
  //     font-size: 12px;
  //     color: #9b9b9b;
  //   }
  // }

  .baseForm {
    max-width: 800px;
    min-width: 600px;
    .coverPhotoUpload {
      :global .logoUpload {
        button {
          top: 45%;
          margin-top: -16px;
        }
        :global .ant-upload.ant-upload-select-picture-card > .ant-upload {
          display: inline-block;
          padding: 0;
          width: 210px;
          height: 140px;
        }
        :global .ant-upload-select-picture-card {
          border: none;
          width: 210px;
          height: 140px;
          background-color: #f7f7f7;
        }
        :global .ant-upload-list-picture-card-container {
          width: 210px;
          height: 140px;
          padding: 0;
        }
        :global .ant-upload-list-picture-card .ant-upload-list-item {
          width: 210px;
          height: 140px;
          padding: 0;
        }
        .img {
          width: 210px;
          height: 140px;
          object-fit: cover;
        }
        .uploadCover {
          width: 210px;
          height: 140px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
    }
    .uploadSelect {
      position: absolute;
    }
    :global .ant-divider-horizontal {
      margin: 0 0 25px 0;
    }
  }
  .urlInput {
    // width: 130px;
    // border-bottom: 1px solid #cfd1d7;
    margin-right: 10px;
    // color: #cfd1d7;
  }
  .notOnline {
    font-size: 13px;
    color: #9b9b9b;
    font-style: italic;
  }
  .privacyForm {
    max-width: 800px;
    min-width: 600px;
    .checkBoxLabel {
      margin-left: 10px;
    }
    :global .ant-list-header {
      padding-bottom: 0px;
    }
    :global .ant-list-footer {
      padding-bottom: 0px;
    }
    :global .ant-divider-horizontal {
      margin: 0 0 25px 0;
    }
  }
  .downloadForm {
    max-width: 800px;
    min-width: 600px;
    width: 100%;
    .checkBoxLabel {
      margin-left: 10px;
    }
    :global .ant-list-header {
      padding-bottom: 0px;
    }
    :global .ant-list-footer {
      padding-bottom: 0px;
    }
  }
  .setButton {
    float: right;
  }
  .collectForm {
    max-width: 800px;
    min-width: 600px;
    .add_btn {
      color: #1AA77D;
      background: #fff;
      border: 1px solid rgba(26, 167, 125, 0.6);
      border-radius: 2px;
      box-shadow: 0 0 1px rgba(26, 167, 125, 0.6);
    }
    :global .ant-table-tbody > tr > td {
      padding: 16px 0 16px;
    }
    :global .ant-table-thead > tr > th {
      padding: 4px 0;
    }
    .selectedRed :global label{
      color: red;
    }
    .selectedRed :global .ant-select-selector{
      border-color: red;
    }
  }
  .infoFormForm {
    max-width: 800px;
    min-width: 600px;
  }
  .switchLabel {
    margin-left: 12px;
  }
  .Inf_ant_form_item {
    display: inline-block;
    margin-right: 20px;
  }
  .desc {
    font-size: 12px;
    color: #9b9b9b;
    padding-top: 6px;
    font-weight: normal;
  }
  :global .ant-checkbox {
    // checkbox 样式
    :global .ant-checkbox-inner {
      border-radius: 50%;
      border-color: #cfd1d7;
    }
  }

  :global .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @primary-color;
    border-color: @primary-color;
  }
  :global .ant-input-group .ant-input-group-addon {
    padding: 0 10px 0 0;
  }
  :global .ant-form-item-control-input {
    min-height: 20px;
  }
  .noPrice {
    font-size: 12px;
    color: #9b9b9b;
  }
}
.settingsMenu {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}

.webLayout {
  :global .ant-radio-button-wrapper {
    margin-right: 1.67rem;
    height: 60px;
    width: 120px;
    text-align: center;
    line-height: 60px;
  }
  :global
    .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #F9F9F9;
    border: #1AA77D 1px solid;
    color: #000;
  }
  :global
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: rgba(0, 0, 0, 0);
  }
  :global .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #000;
    background: #F9F9F9;
    border-color: #39b38a;
  }
  :global .ant-radio-button-wrapper {
    border: 1px solid #d9d9d9;
  }
  .flexArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .buttonCheckIcon {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 2px;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;