.titleBar {
  padding: 24px 50px 23px !important;
  border-bottom: 1px solid #e6e6e6;
  .backBtn {
    width: 28px;
    height: 22px;
    line-height: 20px;
    padding: 0;
    margin-right: 10px;
    :global .iconfont {
      font-size: 12px;
    }
  }
  .cancelBtn {
    color: #9b9b9b;
    border: none;
    box-shadow: none;
  }
}
.newProductsPage {
  overflow-x: hidden;
  height: calc(100vh - 88px);
  padding: 30px 50px;
  background: #fff;
}
.productForm {
  width: 80%;
  :global .ant-form-item-label {
    font-weight: bold;
  }
  .productContent {
    max-width: 800px;
    min-width: 600px;
  }
  :global .iconfont{
    font-size: 12px;
    cursor: pointer;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;