/*
 * @Author: melon 
 * @Date: 2020-06-29 11:20:04 
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-22 11:11:30
 */

.register_form_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .Title {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    margin-bottom: 24px;
  }

  .register_form {
    width: 418px;

    .user_register_input {
      width: 418px;
    }

    .register_attention {
      font-size: 12px;
      margin: 24px 0;

      a {
        color: #000000;
      }
    }

    .register_btn_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .register_btn_item {
        padding: 0;
      }

      .register_btn {
        background: #1AA77D;
        border-radius: 2px;
        width: 180px;
        height: 45px;
        border: 1px solid #1AA77D;
      }
    }

    :global .ant-form-item-label {
      padding: 0;
    }
  }
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;