.member_wrapper {
  max-width: 1000px;
  min-width: 600px;

  :global .ant-table-tbody > tr > td {
    padding: 16px 0 16px;
  }
  :global .ant-table-thead > tr > th {
    padding: 4px 0;
  }

  .Title {
    font-size: 14px;
    color: #4A4A4A;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .add_btn {
    color: #1AA77D;
    background: #fff;
    border: 1px solid rgba(26, 167, 125, 0.6);
    border-radius: 2px;
    box-shadow: 0 0 1px rgba(26, 167, 125, 0.6);
  }
  :global .Inf_product_card--GSMbJ .Inf_product_card_content--PBCiV{
    margin-left: 6px;
  }
  :global .ant-divider-horizontal {
    margin: 25px 0 0 0;
  }
}
.span{
  font-size: 12px;
  color: #9B9B9B;
  letter-spacing: 0;
  font-weight: 400;
}


.account_cell {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 8px;
    width: 36px;
    height: 36px;
    border-radius: 18px;
  }

  .nickname {
    font-size: 14px;
    color: #4A4A4A;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 500;
  }
  .mobile {
    font-size: 12px;
    color: #9B9B9B;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 400;
  }
}
.role_cell {
  :global .ant-select-selection-item {
    margin-right: 10px;
    margin-top: 3px;
  }
  :global .ant-select-arrow {
    right: 12px;
    top: 58%;
    :global .iconfont {
      font-size: 12px;
    }
  }
  :global .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 10px 0 0;
  }
}
.menuBtn {
  text-align: right;
}
.settingsMenu {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #aaacb1;
  :global ul {
    border-right: none;
  }
  :global li {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    padding: 0px !important;
    a {
      color: #4a4a4a;
      font-weight: normal;
    }
  }
  :global .ant-menu > .ant-menu-item-divider {
    height: 1px !important;
    margin: 6px 0 !important;
    background-color: #f0f0f0 !important;
  }
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;