.addTitle{
  font-size: 16px;
  color: #4A4A4A;
  line-height: 18px;
  font-weight: 300;
}
.subTitle{
  color: #4A4A4A;
  letter-spacing: 0;
}
.close{
  width: 11px;
  margin-right: 12px;
  cursor: pointer;
}
.memberIcon{
  margin-right: 12px;
}
.footerP{
  display: flex;
}
.firstBtn{
  line-height: 40px;
  font-size: 13px;
  color: #066DD5;
  cursor: pointer;
}
.secondBtn{
  color: #9B9B9B;
  margin-left: auto!important;
  :global .ant-btn {
    border: none;
    box-shadow: none;
  }
  :global .ant-btn:hover {
    border: none;
    box-shadow: none;
  }
  :global .ant-btn:focus {
    border: none;
    box-shadow: none;
  }
}

.role_cell {
  :global .ant-select-selection-item {
    text-align: right;
    margin-right: 10px;
    margin-top: 3px;
  }
  :global .ant-select-arrow {
    right: 12px;
    top: 58%;
    :global .iconfont {
      font-size: 12px;
    }
  }
}
.member{
  :global .ant-form-item {
    margin-bottom: 0;
  }
  :global .ant-modal-body {
    padding-bottom: 20px;
  }
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;